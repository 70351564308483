import { Badge, BadgeProps, Drawer, DrawerProps, ScrollArea, Stack, Text, createStyles } from "@mantine/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { TransactionHistoryType, TransactionStatus } from "../../constants";
import { stringIsNullOrEmpty } from "../../utils/Utils";

const useStyles = createStyles((theme, params, getRef) => ({
    container: {
        height: "calc(100% - 44px)",
    },
    divider: {
        marginLeft: -theme.spacing.xl,
        marginRight: -theme.spacing.xl,
    },
    label: {
        fontSize: theme.fontSizes.xs,
        color: theme.colors.gray[6],
        lineHeight: 1.2,
    },
    info: {
        fontSize: theme.fontSizes.sm,
        color: theme.colors.dark[6],
        lineHeight: 1.2,
    },
    stackContainer: {
        "> .mantine-Stack-root": {
            gap: 0,
        },
    },
}));

/**
 * @param {TransactionStatus} [status]
 * @param {string} [statusName]
 * @param {Omit<BadgeProps, 'color'>} [badgeProps]
 */
export function renderStatusBadge(status, badgeProps) {
    if (status != undefined) {
        let statusName = "";
        let color = "";

        switch (status) {
            case TransactionStatus.ACCEPTED:
                color = "teal";
                statusName = "Payment Completed";
                break;
            case TransactionStatus.PENDING:
                color = "orange";
                statusName = "Pending Payment";
                break;
            case TransactionStatus.FAILED:
                color = "red";
                statusName = "Failed";
                break;
            case TransactionStatus.PUBLISHED:
                color = "teal";
                statusName = "Published";
                break;
            case TransactionStatus.PUBLISH_REJECTED:
                color = "red";
                statusName = "Rejected";
                break;
            default:
                break;
        }

        return (
            <Badge {...badgeProps} color={color}>
                {statusName}
            </Badge>
        );
    }
}

/** @param {DrawerProps & {data: TransactionHistoryType}} props */
export default function TransactionHistoryDetailDrawer(props) {
    const { data, opened, position = "right", title = "Detail", padding = "xl", ...restProps } = props;

    const { classes } = useStyles();

    /** @type {[TransactionHistoryType|undefined, Dispatch<SetStateAction<TransactionHistoryType|undefined>>]} */
    const [transaction, setTransaction] = useState();

    useEffect(() => {
        if (opened) {
            setTransaction(data);
        }
    }, [opened]);

    return (
        <Drawer {...restProps} opened={opened} position={position} title={title} padding={padding}>
            <ScrollArea className={classes.container}>
                <Stack spacing="xs" className={classes.stackContainer}>
                    <Text>{transaction?.transactionId}</Text>
                    <Stack>
                        <Text className={classes.label} color="dimmed">
                            Type
                        </Text>
                        <Text className={classes.info}>{transaction?.typeName}</Text>
                    </Stack>
                    <Stack>
                        <Text className={classes.label}>Status</Text>
                        {renderStatusBadge(transaction?.status, transaction?.statusName, { sx: { width: "fit-content" } })}
                    </Stack>
                    <Stack>
                        <Text className={classes.label}>Reference Number</Text>
                        <Text className={classes.info}>{transaction?.bankReferenceNumber ?? "N/A"}</Text>
                    </Stack>
                    <Stack>
                        <Text className={classes.label}>Username</Text>
                        <Text className={classes.info}>{transaction?.username}</Text>
                    </Stack>
                    <Stack>
                        <Text className={classes.label}>Alias</Text>
                        <Text className={classes.info}>{transaction?.alias ?? "N/A"}</Text>
                    </Stack>
                    <Stack>
                        <Text className={classes.label}>Initial Amount</Text>
                        <Text className={classes.info}>{transaction?.initialAmount?.toFixed(2)}</Text>
                    </Stack>
                    <Stack>
                        <Text className={classes.label}>Amount</Text>
                        <Text className={classes.info}>{transaction?.amount?.toFixed(2)}</Text>
                    </Stack>
                    <Stack>
                        <Text className={classes.label}>Final Amount</Text>
                        <Text className={classes.info}>{transaction?.finalAmount?.toFixed(2)}</Text>
                    </Stack>
                    <Stack>
                        <Text className={classes.label}>Cash Amount</Text>
                        <Text className={classes.info}>{transaction?.cashAmount?.toFixed(2) ?? "N/A"}</Text>
                    </Stack>
                    <Stack>
                        <Text className={classes.label}>Completion Time</Text>
                        <Text className={classes.info}>{transaction?.completionTime ? dayjs(transaction.completionTime).format("L LTS") : "N/A"}</Text>
                    </Stack>
                    <Stack>
                        <Text className={classes.label}>Remark</Text>
                        <Text className={classes.info}>{!stringIsNullOrEmpty(transaction?.remark) ? transaction.remark : "N/A"}</Text>
                    </Stack>
                    <Stack>
                        <Text className={classes.label}>Created By</Text>
                        <Text className={classes.info}>{transaction?.createdByUsername}</Text>
                    </Stack>
                    <Stack>
                        <Text className={classes.label}>Created Date</Text>
                        <Text className={classes.info}>{dayjs(transaction?.createdDate).format("L LTS")}</Text>
                    </Stack>
                    <Stack>
                        <Text className={classes.label}>Modified By</Text>
                        <Text className={classes.info}>{transaction?.modifiedByUsername}</Text>
                    </Stack>
                    <Stack>
                        <Text className={classes.label}>Modified Date</Text>
                        <Text className={classes.info}>{dayjs(transaction?.modifiedDate).format("L LTS")}</Text>
                    </Stack>
                </Stack>
            </ScrollArea>
        </Drawer>
    );
}
