import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { Config, ConfigEnum } from "./Config";
import { ApiRoutes } from "../constants/ApiRoutes";

/**
 * @typedef {Object} ResponseData
 * @property {boolean} success
 * @property {string} message
 * @property {*} [data]
 * @property {number} [totalCount]
 * @property {number} code
 */

const ApiKey = {
    CONTENT_TYPE: "Content-Type",
    APPLICATION_JSON: "application/json",
    AUTHORIZATION: "Authorization",
};

const ApiEngine = axios.create({
    baseURL: ApiRoutes.BASE_URL,
    headers: {
        [ApiKey.CONTENT_TYPE]: ApiKey.APPLICATION_JSON,
    },
    timeout: 60000,
});

ApiEngine.interceptors.request.use(
    (config) => interceptRequest(config),
    (error) => interceptError(error)
);

ApiEngine.interceptors.response.use(
    (response) => interceptResponse(response),
    (error) => interceptError(error)
);

/**
 * @param {AxiosRequestConfig} requestConfig
 */
async function interceptRequest(requestConfig) {
    const token = Config.getInstance().getValue(ConfigEnum.TOKEN);

    if (token) {
        requestConfig.headers[ApiKey.AUTHORIZATION] = `Bearer ${token}`;
    }
    return requestConfig;
}

/**
 * @param {AxiosError<ResponseData>} error
 */
async function interceptError(error) {
    let { message, success, code } = error.response.data;

    return {
        success: success ?? false,
        message: message ?? error.message,
        code: code ?? error.code,
    };
}

/**
 * @param {AxiosResponse} response
 */
async function interceptResponse(response) {
    return response.data;
}

export default ApiEngine;
