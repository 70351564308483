import { Button, Group, LoadingOverlay, Loader, Modal, ModalProps, Stack, Text, TextInput, createStyles, NumberInput, Select } from "@mantine/core";
import { MIME_TYPES } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { useEffect, useRef, useState } from "react";
import { ApiRoutes, PaymentGatewayType, PasswordFormType, RoleType, _PAYMENT_GATEWAY_TYPE_OPTIONS } from "../../constants";
import ApiEngine, { ResponseData } from "../../utils/ApiEngine";
import { getFormData, showAlert, constructQueryString, stringIsNullOrEmpty } from "../../utils/Utils";
import { Dropzone } from "../elements/Dropzone";
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedState, useDidUpdate, useDisclosure } from "@mantine/hooks";
import { IconUser } from "@tabler/icons";

const useStyles = createStyles((theme, params, getRef) => ({
    avatar: {
        objectFit: "contain",
        backgroundColor: "white",
        border: "2px solid",
        borderColor: theme.colorScheme === "dark" ? "#FFFFFF" : "#000000",
        borderRadius: theme.radius.md,
        width: 100,
        height: 100,
    },
    avatarContainer: {
        position: "relative",
        cursor: "pointer",
        width: "fit-content",
        margin: "auto",

        ".icon.icon-tabler-arrow-up-circle": {
            background: "white",
            position: "absolute",
            bottom: -5,
            left: 0,
            right: -85,
            margin: "auto",
            borderRadius: theme.radius.xl,
            height: 32,
            width: 32,
        },
    },
}));

/**
 * @param {ModalProps & {paymentGateway?: PaymentGatewayType, successCallback?: function}} props
 */
export default function CreateUpdateModal(props) {
    const { opened, centered = true, paymentGateway: paymentGatewayProp, successCallback, ...restProps } = props;

    const { classes } = useStyles();
    /** @type {[PaymentGatewayType|undefined, Dispatch<SetStateAction<PaymentGatewayType|undefined>>]} */
    const [paymentGateway, setPaymentGateway] = useState();
    const [loading, setLoading] = useState(false);
    var _userData = useSelector((state) => state.authState.userData);

    const form = useForm({
        initialValues: {
            title: "",
            typeId: PaymentGatewayType.ONLINE_BANKING,
            apiKey: "",
            apiSecret: "",
            baseUrl: "",
            callbackUrl: "",
            responseUrl: "",
            secretKey: "",
            minTopUpAmount: undefined,
            maxTopUpAmount: undefined,
            processingFee: 0,
        },
        validate: {
            title: (value) => (value === "" ? true : undefined),
            roleId: (value) => (value === "" ? true : undefined),
            apiKey: (value) => (value === "" ? true : undefined),
            apiSecret: (value) => (value === "" ? true : undefined),
            baseUrl: (value) => (value === "" ? true : undefined),
            callbackUrl: (value) => (value === "" ? true : undefined),
            responseUrl: (value) => (value === "" ? true : undefined)
        },
    });


    /** @param {Partial<PaymentGatewayType>} values */
    async function onSubmit(values) {
        try {
            setLoading(true);

            let requestFn;
            let url = ApiRoutes.PAYMENT_GATEWAY;
            if (paymentGateway) {
                requestFn = ApiEngine.put;
                url += `/${values.id}`;
            } else {
                requestFn = ApiEngine.post;
                values.isActive = false;
            }

            console.log("PaymentGateway params", values);

            /** @type {ResponseData} */
            const response = await requestFn(url, values);

            if (!response.success) {
                throw response.message;
            }
            showAlert("success", response.message);
            typeof successCallback === "function" && successCallback(values);
            props.onClose();
        } catch (error) {
            console.log("Error", error);
            showAlert("fail", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (opened) {
            form.reset();
            setPaymentGateway(paymentGatewayProp);

            if (paymentGatewayProp) {
                form.setValues(paymentGatewayProp);
                form.setFieldValue('image', null);
            }
        }
    }, [opened]);

    return (
        <Modal size={'lg'} opened={opened} centered={centered} title={`${paymentGateway ? "Edit" : "Add"} PaymentGateway`} {...restProps}>
            <form onSubmit={form.onSubmit(onSubmit)}>
                <Stack>
                    <Group grow wrap="nowrap">
                        <TextInput {...form.getInputProps("title")} label="Title" placeholder="Title" withAsterisk />
                        <Select {...form.getInputProps("typeId")} label={"Type"} placeholder={"Type"} data={_PAYMENT_GATEWAY_TYPE_OPTIONS} withAsterisk />
                    </Group>
                    <Group grow wrap="nowrap">
                        <TextInput {...form.getInputProps("apiKey")} label="API Key" placeholder="API Key" withAsterisk />
                        <TextInput {...form.getInputProps("apiSecret")} label="API Secret" placeholder="API Secret" withAsterisk />
                    </Group>
                    <Group grow wrap="nowrap">
                        <TextInput {...form.getInputProps("baseUrl")} label="Base URL" placeholder="Base URL" withAsterisk />
                        <TextInput {...form.getInputProps("callbackUrl")} label="Callback URL" placeholder="Callback URL" withAsterisk />
                    </Group>
                    <Group grow wrap="nowrap">
                        <TextInput {...form.getInputProps("responseUrl")} label="Response URL" placeholder="Response URL" withAsterisk />
                        <TextInput {...form.getInputProps("secretKey")} label="Secret Key" placeholder="Secret Key" />
                    </Group>
                    <Group grow wrap="nowrap">
                        <NumberInput {...form.getInputProps("minTopUpAmount")} label="Minimum Amount" placeholder="Minimum Amount" hideControls min={0} precision={2} />
                        <NumberInput {...form.getInputProps("maxTopUpAmount")} label="Maximum Amount" placeholder="Maximum Amount" hideControls min={0} precision={2} />
                    </Group>
                    <Group grow wrap="nowrap">
                        <NumberInput {...form.getInputProps("processingFee")} label="Processing Fee" placeholder="Processing Fee" hideControls min={0} precision={2} withAsterisk />
                    </Group>
                    <Group position="right">
                        <Button variant="default" onClick={props.onClose}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="filled">
                            Save
                        </Button>
                    </Group>
                </Stack>
            </form>
            <LoadingOverlay visible={loading} />
        </Modal>
    );
}
