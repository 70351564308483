import React, { useState, CSSProperties, useEffect, useRef } from 'react'
import { useTransition, animated, AnimatedProps, useSpringRef } from '@react-spring/web'
import Layout from "../../components/layout";
import { Flex, Button, Drawer, Group, Stack, Text, TextInput, Switch, Card, Badge, Image, CopyButton, Space } from "@mantine/core";
import { useDisclosure } from '@mantine/hooks';
import { APP_NAME, ApiKey, ApiRoutes, TransactionStatus, TippingType } from "../../constants";
import ApiEngine, { ResponseData } from "../../utils/ApiEngine";
import { showAlert, stringIsNullOrEmpty } from "../../utils/Utils";
import { useDispatch, useSelector } from 'react-redux';
import { renderStatusBadge } from "../../components/report/TransactionHistoryDetailDrawer";
import dayjs from "dayjs";
import { IconDownload } from '@tabler/icons-react';
import ConfirmationDialog from '../../components/elements/ConfirmationDialog';

export default function Dashboard() {
    const dashboardContainerRef = useRef()
    const [index, set] = useState(0)
    const transRef = useSpringRef()
    var _poolRef = useRef();
    const [dialogOpen, setDialogOpen] = useState(false);

    const transitions = useTransition(index, {
        ref: transRef,
        keys: null,
        from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
        enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
        leave: { opacity: 0, transform: 'translate3d(-100%,0,0)' },
    })
    const [cardBackgroundStyle, setCardBackgroundStyle] = useState({
        width: '100%',
        height: '100%',
    });
    const [tipList, setTipList] = useState([]);
    const [opened, { open, close }] = useDisclosure(false);
    const [pendingTipList, setPendingTipList] = useState([]);

    const userData = useSelector((state) => state.authState.userData);

    useEffect(() => {
        getTippingTransaction();
        _poolRef.current = setInterval(getTippingTransaction, 5000);

        return () => {
            if (_poolRef.current != null) {
                clearInterval(_poolRef.current);
            }
        }
    }, []);

    useEffect(() => {
        if (typeof (dashboardContainerRef) === 'object' && dashboardContainerRef !== null) {
            const { current } = dashboardContainerRef
            if (typeof (current) === 'object' && current !== null) {
                const w = current.offsetWidth
                const h = current.offsetHeight
                const url = `https://picsum.photos/${w}/${h}?${randInt(1000)}`;
                setCardBackgroundStyle({
                    width: '100%',
                    height: '100%',
                    background: `linear-gradient(to bottom,${randRgba()},${randRgba()}),url('${url}')`,
                })
            }
        }

        transRef.start()
    }, [tipList]);

    const onClick = () => {
        if (tipList.length > 0) {
            let oldTipList = tipList;
            let newTipList = [];

            if (tipList.length > 1) {
                oldTipList.shift();
                newTipList = oldTipList;
            }

            setTipList(newTipList);
            set(state => (state + 1) % 3)
        }
    }

    function randInt(n = 10) {
        return Math.floor(Math.random() * n)
    }

    function randRgba(a = .75) {
        return `rgba(${[...Array(3).keys()].map(e => randInt(255)).join(',')},${a})`
    }

    async function getTippingTransaction() {
        try {
            /** @type {ResponseData} */
            const response = await ApiEngine.get(`${ApiRoutes.REPORT_TIPPING}?status=${TransactionStatus.ACCEPTED}&orderBy=1`);

            if (!response.success) {
                throw response.message;
            }

            setPendingTipList(response[ApiKey._API_DATA_KEY]);
        } catch (error) {
            // showAlert("fail", error);
        }
    }

    function toDataURL(url) {
        return fetch(url)
            .then((response) => {
                return response.blob();
            })
            .then((blob) => {
                return URL.createObjectURL(blob);
            });
    }

    async function download(url, name) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = await toDataURL(url);
        a.download = 'image.jpg';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const handleReject = async (value) => {
        try {
            /** @type {ResponseData} */
            let params = {
                "id": tipList[0].id,
                "status": TransactionStatus.PUBLISH_REJECTED,
                "remark": value
            };
            const response = await ApiEngine.put(`${ApiRoutes.UPDATE_TIPPING_STATUS}`, params);

            if (!response.success) {
                throw response.message;
            }
            else {
                onClick();
                getTippingTransaction();
            }
        } catch (error) {
            showAlert("fail", error);
        }

        setDialogOpen(false);
    };

    return (
        <div ref={dashboardContainerRef} className='dashboard'>
            <Drawer position="right" opened={opened} onClose={close} title="Tips" padding={10}>
                {/* Drawer content */}
                <Flex>
                    {
                        pendingTipList.length > 0 ?
                            <Group>
                                {
                                    pendingTipList.map(item =>
                                        <Card
                                            onClick={() => {
                                                setTipList([item]);
                                            }}
                                            style={{ cursor: "pointer", padding: "8px 15px" }} shadow="sm" padding="lg" radius="md" withBorder>
                                            <Group justify="space-between">
                                                <Text fw={700} c="green">+{item.amount}</Text>
                                            </Group>
                                            <Text size="sm" fw="700">
                                                {item.typeId == TippingType._TIPPING ? item.receiverStaffName : item.message}
                                            </Text>
                                            {
                                                !stringIsNullOrEmpty(item?.nickname) &&
                                                <Text size="sm" fw="700">By: {item?.nickname}</Text>
                                            }
                                            {
                                                !stringIsNullOrEmpty(item.songName) &&
                                                <Text size="sm" fw="700">Song: {item.songName}</Text>
                                            }
                                            {
                                                !stringIsNullOrEmpty(item.displayRemark) &&
                                                <Text size="sm" fw="700">[{item.displayRemark}]</Text>
                                            }
                                            <Text size="sm">
                                                {dayjs(item.processedDate).format("DD/MM/YYYY hh:mm:ss A")}
                                            </Text>
                                        </Card>
                                    )
                                }
                            </Group>
                            :
                            <Text size="md" fw="bold">No pending tips...</Text>
                    }
                </Flex>
            </Drawer>
            <Group className="tip-list-container">
                <Flex>
                    <Group>
                        {/* <Button onClick={() => {
                            setTipList([...tipList, {
                                message: "Happy Birthday " + _NAMES[Math.floor(Math.random() * _NAMES.length)] + "!"
                            }])
                        }}>Add</Button> */}
                        <Button onClick={open} variant="default" color="red" size="md" radius="xl" className="tip-count">{pendingTipList.length}</Button>
                    </Group>
                </Flex>
            </Group>
            <div className={`flex fill dashboard-container`}>
                {transitions((style, i) => {
                    return tipList.length > 0 ?
                        <animated.div className="tipping-card"
                            style={
                                !stringIsNullOrEmpty(tipList[0].giftVirtualImage) ?
                                    {
                                        ...style,
                                        ...{
                                            backgroundImage: !stringIsNullOrEmpty(tipList[0].giftVirtualImage) ? `url(${tipList[0].giftVirtualImage})` : "unset",
                                            backgroundPosition: "center",
                                            backgroundSize: "cover"
                                        }
                                    } :
                                    {
                                        ...style,
                                        ...cardBackgroundStyle,
                                    }}
                        >
                            <Flex justify='center' align='center' direction={'column'}>
                                {
                                    !stringIsNullOrEmpty(tipList[0].uploadedImage) ?
                                        <>
                                            <Image
                                                style={{ maxWidth: '200px', width: '100%' }}
                                                fit="contain"
                                                radius="md"
                                                src={tipList[0].uploadedImage}
                                            />
                                            <Space h={'xs'} />
                                            <Button bg={'lime'} leftIcon={<IconDownload size={14} />} onClick={() => { download(tipList[0].uploadedImage) }}>Download</Button>
                                        </>
                                        : <></>
                                }
                                <Group>
                                    <p>
                                        {tipList[0].typeId == TippingType._TIPPING ? tipList[0].receiverStaffName : tipList[0].message}
                                    </p>
                                    {
                                        tipList[0].typeId != TippingType._TIPPING &&
                                        <CopyButton value={tipList[0].message}>
                                            {({ copied, copy }) => (
                                                <Button bg={copied ? 'blue' : 'green'} onClick={copy}>
                                                    {copied ? 'Copied' : 'Copy'}
                                                </Button>
                                            )}
                                        </CopyButton>
                                    }
                                </Group>
                                {
                                    !stringIsNullOrEmpty(tipList[0].songName) &&
                                    <p>Song: {tipList[0].songName}</p>
                                }
                                {
                                    !stringIsNullOrEmpty(tipList[0]?.nickname) &&
                                    <p>[By: {tipList[0]?.nickname}]</p>
                                }
                                {
                                    !stringIsNullOrEmpty(tipList[0]?.displayRemark) &&
                                    <p style={{ "text-align": "center" }}>[{tipList[0]?.displayRemark}]</p>
                                }
                            </Flex>
                            <Flex className='approval-btn-container' justify='center' align='center'>
                                <Button
                                    className="approve-btn"
                                    onClick={async () => {
                                        try {
                                            /** @type {ResponseData} */
                                            let params = {
                                                "id": tipList[0].id,
                                                "status": TransactionStatus.PUBLISHED,
                                                "remark": ""
                                            };
                                            const response = await ApiEngine.put(`${ApiRoutes.UPDATE_TIPPING_STATUS}`, params);

                                            if (!response.success) {
                                                throw response.message;
                                            }
                                            else {
                                                onClick();
                                                getTippingTransaction();
                                            }
                                        } catch (error) {
                                            showAlert("fail", error);
                                        }
                                    }}
                                    variant="default"
                                    color="outline"
                                    size="md"
                                    radius="xl"
                                >
                                    Done Published
                                </Button>
                                <Button
                                    className="reject-btn"
                                    onClick={async () => {
                                        setDialogOpen(true);
                                    }}
                                    bg="red"
                                    variant="filled"
                                    color="red"
                                    size="md"
                                    radius="xl"
                                >
                                    Reject
                                </Button>
                            </Flex>
                        </animated.div> :
                        <animated.div className="tipping-card" style={{ ...style }}><p style={{ color: 'black' }}>Screen</p></animated.div>
                })}
            </div>
            <ConfirmationDialog
                opened={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onConfirm={handleReject}
                title={"Tip Reject Confirmation"}
                inputLabel={"Remark"}
                confirmBtnTxt={"Reject"}
                confirmBtnColor={"red"}
            />
        </div>
    )
}

Dashboard.getLayout = function getLayout(page) {
    return <Layout>{page}</Layout>;
};
