import { ActionIcon, Badge, Divider, Grid, Group, Anchor, Select, TextInput, Stack, Text, Flex, Button, Loader, Modal } from "@mantine/core";
import { DateRangePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { IconSettings, IconCalendar, IconCashBanknote, IconClipboardCheck, IconIdBadge2, IconRotate, IconSearch, IconUser } from "@tabler/icons";
import { ColumnHelper, createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useMemo, useRef, useState, useEffect } from "react";
import { DataGrid } from "../../components/elements/DataGrid";
import Layout from "../../components/layout";
import { APP_NAME, ApiRoutes, RoleType, TransactionHistoryType, TransactionStatus, TransactionType, TippingType, SettlementStatus, Status, Role } from "../../constants";
import ApiEngine, { ResponseData } from "../../utils/ApiEngine";
import { constructQueryString, isObjectEmpty, showAlert, stringIsNullOrEmpty } from "../../utils/Utils";
import { useDispatch, useSelector } from 'react-redux';
import { setBusy, setIdle } from "../../redux/AppAction";

const columnHelper = createColumnHelper();
const settlementStatusOpts = Object.entries(SettlementStatus).map(([key, value]) => ({ label: key, value: `${value}` }));

function renderStatusBadge(value, badgeProps) {
    let label = "Pending";
    let color = "orange";

    if (value === SettlementStatus.PENDING) {
        label = "Pending";
        color = "orange";
    }
    else if (value === SettlementStatus.SETTLED) {
        label = "Settled";
        color = "teal";
    }
    else {
        label = "Rejected";
        color = "red";
    }

    return (
        <Badge color={color} {...badgeProps}>
            {label}
        </Badge>
    );
}

function renderInvoiceLink(invoiceNumber, link) {
    return <Anchor href={link} target="_blank">{invoiceNumber}</Anchor>
}

export default function Settlement() {
    const _pendingInitialValues = {
        shopId: ""
    };
    const _initialValues = {
        status: "",
        shopId: "",
        dateRange: [new Date(), new Date()],
    };
    const [pendingFilterValues, setPendingFilterValues] = useState();
    const [filterValues, setFilterValues] = useState();
    const [searchShopLoading, setSearchShopLoading] = useState(false);
    const [shopOpts, setShopOpts] = useState([]);
    const [updateDetails, setUpdateDetails] = useState({});
    const [remark, setRemark] = useState("");
    const pendingdataGridRef = useRef();
    const dataGridRef = useRef();
    var _userData = useSelector((state) => state.authState.userData);
    var _dispatch = useDispatch();

    function renderAction(row) {
        if (row.original.status == SettlementStatus.PENDING) {
            return (
                <Flex
                    gap={"xs"}
                    justify="flex-start"
                    align="flex-start"
                    direction="row"
                    wrap="wrap"
                >
                    <Button variant="light" onClick={() => setUpdateDetails({ ...row.original, settle: true })}>Settle</Button>
                    <Button variant="light" color="red" onClick={() => setUpdateDetails({ ...row.original, settle: false })}>Reject</Button>
                </Flex>
            )
        }
        else {
            return <></>
        }
    }

    const columns = useMemo(
        () => [
            columnHelper.accessor("shopName", { header: "Shop" }),
            columnHelper.accessor((row) => dayjs(row.month).format("DD/MM/YYYY"), { header: "Date" }),
            columnHelper.accessor((row) => "RM " + row.totalAmount, { header: "Total Sales" }),
            columnHelper.accessor("invoiceNumber", {
                header: "InvoiceNumber",
                cell: ({ getValue, row }) => renderInvoiceLink(getValue(), row.original.path)
            }),
            columnHelper.accessor("status", {
                header: "Status",
                cell: ({ getValue, row }) => renderStatusBadge(getValue()),
            }),
            columnHelper.accessor("remark", { header: "Remark" }),
            columnHelper.accessor((row) => (row.processedDate ? dayjs(row.processedDate).format("DD/MM/YYYY hh:mm:ss A") : ""), { header: "Process Date" }),
        ],
        []
    );

    const pendingColumns = useMemo(
        () => [
            columnHelper.accessor("shopName", { header: "Shop" }),
            columnHelper.accessor((row) => dayjs(row.settlementDate).format("DD/MM/YYYY"), { header: "Date" }),
            columnHelper.accessor((row) => "RM " + row.totalAmount, { header: "Total Sales" }),
            columnHelper.accessor("action", {
                header: "Action",
                cell: ({ getValue, row }) =>
                    <Flex
                        gap={"xs"}
                        justify="flex-start"
                        align="flex-start"
                        direction="row"
                        wrap="wrap"
                    >
                        <Button
                            bg="green"
                            variant="filled"
                            color="green"
                            onClick={() => setUpdateDetails({ ...row.original, settle: true })}
                        >
                            Settle
                        </Button>
                    </Flex>
            }),
        ],
        []
    );

    const form = useForm({
        initialValues: _initialValues
    });

    const pendingForm = useForm({
        initialValues: _pendingInitialValues
    });

    useEffect(() => {
        pendingForm.reset();
        form.reset();

        if (_userData.roleId != RoleType.SHOP) {
            searchShopOpts("");
        }

        pendingForm.onSubmit(onSubmitPending)();
        form.onSubmit(onSubmit)();
    }, []);

    function onSubmitPending(values) {
        let filter = { ...values, v: dayjs().unix() };

        setPendingFilterValues(filter);
    }

    function onSubmit(values) {
        let filter = { ...values, v: dayjs().unix() };

        if (values.dateRange && values.dateRange.every((d) => d)) {
            filter.startDate = dayjs(values.dateRange[0]).startOf("month").format("YYYY-MM-DD HH:mm:ss");
            filter.endDate = dayjs(values.dateRange[1]).endOf("month").format("YYYY-MM-DD HH:mm:ss");

            delete filter.dateRange;
        }

        setFilterValues(filter);
    }

    async function searchShopOpts(value) {
        try {
            setSearchShopLoading(true);

            let url = constructQueryString(ApiRoutes.SHOP, { status: Status._ENABLED, username: !stringIsNullOrEmpty(value) ? value : "" });
            /** @type {ResponseData} */
            const response = await ApiEngine.get(url);

            if (!response.success) {
                throw response.message;
            }

            let data = response.data ?? [];

            setShopOpts(data.map(({ id, username }) => ({ label: username, value: id })));
        } catch (error) {
            showAlert("fail", error);
        } finally {
            setSearchShopLoading(false);
        }
    }

    function onModalClose() {
        setUpdateDetails({});
        setRemark("");
    }

    async function updateSettlementStatus() {
        try {
            let params = {
                "month": updateDetails.month,
                "shopId": updateDetails.shopId,
                "remark": remark,
                "status": updateDetails.settle ? SettlementStatus.SETTLED : SettlementStatus.REJECTED
            }

            const response = await ApiEngine.put(ApiRoutes.UPDATE_SETTLEMENT_STATUS, params);

            if (!response.success) {
                throw response.message;
            }
            else {
                onModalClose();
                showAlert("success", "Operation Success");
                form.onSubmit(onSubmit)();
            }
        } catch (error) {
            showAlert("fail", error);
        } finally {
            setSearchShopLoading(false);
        }
    }

    async function approveSettlement() {
        _dispatch(setBusy());

        try {
            let params = {
                "shopId": updateDetails.shopId,
                "remark": remark,
            }

            const response = await ApiEngine.post(ApiRoutes.APPROVE_PENDING_SETTLEMENT, params);

            if (!response.success) {
                throw response.message;
            }
            else {
                onModalClose();
                showAlert("success", "Operation Success");
                pendingForm.onSubmit(onSubmitPending)();
                form.onSubmit(onSubmit)();
            }
        } catch (error) {
            showAlert("fail", error);
        } finally {
            setSearchShopLoading(false);
            _dispatch(setIdle());
        }
    }

    return (
        <>
            <Text size="xl">Pending Settlement</Text>
            <Divider mt="xs" mb="md" />
            <Stack>
                <form onSubmit={pendingForm.onSubmit(onSubmitPending)}>
                    <Grid>
                        <Grid.Col>
                            <Group>
                                {_userData.roleId != RoleType.SHOP && <Select
                                    {...pendingForm.getInputProps("shopId")}
                                    label="Shop"
                                    placeholder="Shop"
                                    icon={<IconUser size={18} />}
                                    data={shopOpts}
                                    searchable
                                    clearable
                                    nothingFoundMessage="Nothing found..."
                                    rightSection={searchShopLoading && <Loader size="xs" />}
                                />}
                                <Group noWrap spacing="xs" sx={{ alignSelf: "flex-end" }} mb={1}>
                                    <ActionIcon
                                        variant="default"
                                        radius="lg"
                                        size="lg"
                                        onClick={() => {
                                            pendingForm.reset();
                                            onSubmitPending(_pendingInitialValues);
                                        }}
                                    >
                                        <IconRotate size={20} />
                                    </ActionIcon>
                                    <ActionIcon type="submit" variant="filled" radius="lg" size="lg" color="blue">
                                        <IconSearch size={20} />
                                    </ActionIcon>
                                </Group>
                            </Group>
                        </Grid.Col>
                    </Grid>
                </form>
                {
                    !isObjectEmpty(pendingFilterValues) &&
                    <DataGrid
                        api={ApiRoutes.PENDING_SETTLEMENT_SUM}
                        columns={pendingColumns}
                        filterValues={pendingFilterValues}
                        highlightOnHover
                        paginationProps={{ hidden: true }}
                        ref={pendingdataGridRef}
                    />
                }
            </Stack>
            <Divider mt="xs" mb="md" />
            <Text size="xl">Settlements</Text>
            <Divider mt="xs" mb="md" />
            <Stack>
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <Grid>
                        <Grid.Col>
                            <Group>
                                <DateRangePicker {...form.getInputProps("dateRange")}
                                    label="Date"
                                    placeholder="Pick Date Range"
                                    icon={<IconCalendar size={18} />}
                                    amountOfMonths={2}
                                    inputFormat="DD/MM/YYYY"
                                    labelFormat="DD/MM/YYYY" />
                                <Select {...form.getInputProps("status")} label="Status" placeholder="Status" icon={<IconClipboardCheck size={18} />} data={settlementStatusOpts} clearable />
                                {
                                    _userData.roleId != RoleType.SHOP &&
                                    <Select
                                        {...form.getInputProps("shopId")}
                                        label="Shop"
                                        placeholder="Shop"
                                        icon={<IconUser size={18} />}
                                        data={shopOpts}
                                        searchable
                                        clearable
                                        nothingFoundMessage="Nothing found..."
                                        rightSection={searchShopLoading && <Loader size="xs" />}
                                    />
                                }
                                <Group noWrap spacing="xs" sx={{ alignSelf: "flex-end" }} mb={1}>
                                    <ActionIcon
                                        variant="default"
                                        radius="lg"
                                        size="lg"
                                        onClick={() => {
                                            form.reset();
                                            onSubmit(_initialValues);
                                        }}
                                    >
                                        <IconRotate size={20} />
                                    </ActionIcon>
                                    <ActionIcon type="submit" variant="filled" radius="lg" size="lg" color="blue">
                                        <IconSearch size={20} />
                                    </ActionIcon>
                                </Group>
                            </Group>
                        </Grid.Col>
                    </Grid>
                </form>
                {
                    !isObjectEmpty(filterValues) &&
                    <DataGrid
                        api={ApiRoutes.SETTLEMENT_HISTORY}
                        columns={columns}
                        filterValues={filterValues}
                        highlightOnHover
                        paginationProps={{ position: "right" }}
                        ref={dataGridRef}
                    />
                }
                <Modal centered opened={!isObjectEmpty(updateDetails)} onClose={() => onModalClose()} title={<>Are you sure want to <b>{updateDetails.settle ? "settle" : "reject"}</b> this settlement?</>}>
                    <Grid justify="center">
                        <Grid.Col span={12}>
                            <TextInput value={remark} onChange={(e) => setRemark(e.target.value)} placeholder="Person in charge" />
                        </Grid.Col>
                        <Grid.Col span={12} align={"right"}>
                            <Button variant="filled" onClick={async () => { await approveSettlement() }}>Submit</Button>
                        </Grid.Col>
                    </Grid>
                </Modal>
            </Stack>
        </>
    );
}

Settlement.getLayout = function getLayout(page) {
    return <Layout>{page}</Layout>;
};
