import React from "react";
import Login from "../pages/login";
import Dashboard from "../pages/dashboard";
import Admins from "../pages/admins";
import Users from "../pages/users";
import Gifts from "../pages/gifts";
import MessageTemplate from "../pages/mesage-template";
import Shop from "../pages/shops";
import Branch from "../pages/branch";
import Staffs from "../pages/staffs";
import Table from "../pages/table";
import PaymentGateway from "../pages/payment-gateway";
import TransasctionHistory from "../pages/report/transaction-history";
import Settlement from "../pages/report/settlements";
import { RoleType } from "../constants";

/// <summary>
/// Author: CK
/// </summary>
export class Pages {
  static _DEFAULT = '/';
  static _LOGIN = '/login';
  static _DASHBOARD = '/dashboard';
  static _ADMIN_MANAGEMENT = '/admins';
  static _USER_MANAGEMENT = '/users';
  static _STAFF_MANAGEMENT = '/staffs';
  static _GIFT_MANAGEMENT = '/gifts';
  static _MESSAGE_TEMPLATE_MANAGEMENT = '/message-template';
  static _SHOP_MANAGEMENT = '/shops';
  static _BRANCH_MANAGEMENT = '/branch';
  static _TABLE_MANAGEMENT = '/table';
  static _PAYMENT_GATEWAY = '/configuration/payment-gateway';
  static _REPORT_TRANSACTION_HISTORY = '/report/transaction-history';
  static _SETTLEMENT = '/report/settlement';
}

/// <summary>
/// Author: CK
/// </summary>
export const _NON_AUTH_ROUTES = [
  {
    path: Pages._DEFAULT,
    title: "Login",
    auth: false,
    component: <Login />,
  }
]

/// <summary>
/// Author: CK
/// </summary>
export const _AUTH_ROUTES = [
  {
    path: Pages._DEFAULT,
    title: "Dashboard",
    auth: true,
    component: <Dashboard />,
  },
  {
    path: Pages._DASHBOARD,
    title: "Dashboard",
    auth: true,
    component: <Dashboard />,
  },
  {
    path: Pages._ADMIN_MANAGEMENT,
    title: "Admin Management",
    auth: true,
    component: <Admins />,
    role: [RoleType.SUPER_ADMIN]
  },
  {
    path: Pages._USER_MANAGEMENT,
    title: "User Management",
    auth: true,
    component: <Users />,
  },
  {
    path: Pages._STAFF_MANAGEMENT,
    title: "Staff Management",
    auth: true,
    component: <Staffs />,
  },
  {
    path: Pages._SHOP_MANAGEMENT,
    title: "Shop Management",
    auth: true,
    component: <Shop />,
    role: [RoleType.SUPER_ADMIN, RoleType.ADMIN]
  },
  {
    path: Pages._GIFT_MANAGEMENT,
    title: "Gifts",
    auth: true,
    component: <Gifts />,
  },
  {
    path: Pages._MESSAGE_TEMPLATE_MANAGEMENT,
    title: "Message Template",
    auth: true,
    component: <MessageTemplate />,
  },
  {
    path: Pages._BRANCH_MANAGEMENT,
    title: "Branch Management",
    auth: true,
    component: <Branch />,
  },
  {
    path: Pages._TABLE_MANAGEMENT,
    title: "Table Management",
    auth: true,
    component: <Table />,
  },
  {
    path: Pages._PAYMENT_GATEWAY,
    title: "Payment Gateway",
    auth: true,
    component: <PaymentGateway />,
    role: [RoleType.SUPER_ADMIN]
  },
  {
    path: Pages._REPORT_TRANSACTION_HISTORY,
    title: "Transaction Report",
    auth: true,
    component: <TransasctionHistory />,
  },
  {
    path: Pages._SETTLEMENT,
    title: "Settlement",
    auth: true,
    component: <Settlement />,
  },
];