import { useRouter } from "../../hooks/useRouter";
import { AppShell, Burger, Header, useMantineTheme } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { useEffect, useState } from "react";
import NavBar, { NavBarDrawer } from "../../components/navbar";
import { WebRoutes } from "../../constants";
import { ApiRoutes } from "../../constants/ApiRoutes";
import ApiEngine, { ResponseData } from "../../utils/ApiEngine";
import { Config, ConfigEnum } from "../../utils/Config";
import { showAlert } from "../../utils/Utils";

/// <summary>
/// Author: CK
/// </summary>
export default function Layout({ children }) {
    const router = useRouter();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [drawerOpened, drawerHandlers] = useDisclosure(false);
    
    useEffect(() => {
        // async function isAuthorised() {
        //     try {
        //         /** @type {ResponseData} */
        //         const response = await ApiEngine.get(ApiRoutes.IS_AUTHORISED);

        //         if (!response.success) {
        //             Config.getInstance().setData(ConfigEnum.USER, null);
        //             Config.getInstance().setData(ConfigEnum.TOKEN, null);
        //             throw response.message;
        //         }

        //         let data = response?.data;
        //         Config.getInstance().setData(ConfigEnum.USER, data);

        //         // setUserDataState(data);
        //         setIsLoggedIn(true);
        //     } catch (error) {
        //         showAlert("fail", error);
        //         router.navigate(WebRoutes.LOGIN);
        //     }
        // }

        let userData = Config.getInstance().getValue(ConfigEnum.USER);

        if (userData !== null) {
            // isAuthorised();
            setIsLoggedIn(true);
        }

        if (userData === null) {
            router.navigate(WebRoutes.LOGIN);
        }
    }, [router.asPath]);

    if (isLoggedIn) {
        return (
            <>
                <AppShell
                    padding="md"
                    navbarOffsetBreakpoint="sm"
                    navbar={<NavBar />}
                    header={
                        <Header height={60} p="xs">
                            <Burger opened={drawerOpened} onClick={drawerHandlers.toggle} size="sm" mr="xl" />
                        </Header>
                    }
                    styles={(theme) => ({
                        root: {
                            [theme.fn.largerThan(theme.breakpoints.sm - 1)]: {
                                "--mantine-header-height": "0px",
                                "> header": {
                                    display: "none",
                                },
                            },
                        },
                        main: { backgroundColor: "white" },
                    })}
                >
                    {children}
                </AppShell>
                <NavBarDrawer opened={drawerOpened} handlers={drawerHandlers} />
            </>
        );
    }

    return null;
}
