import { ActionIcon, Button, Divider, Group, Stack, Text, TextInput, Badge } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import { IconEdit, IconMessage2, IconPlus, IconRotate, IconSearch, IconUserX, IconUserCheck } from "@tabler/icons";
import { ColumnHelper, createColumnHelper } from "@tanstack/react-table";
import { Dispatch, SetStateAction, useMemo, useRef, useState } from "react";
import { DataGrid } from "../../components/elements/DataGrid";
import CreateUpdateModal from "../../components/payment-gateway/CreateUpdateModal";
import Layout from "../../components/layout";
import { APP_NAME, ApiRoutes, PaymentGatewayType, UserStatusType, _PAYMENT_GATEWAY_TYPE_OPTIONS } from "../../constants";
import ApiEngine, { ResponseData } from "../../utils/ApiEngine";
import { showAlert, stringIsNullOrEmpty, getFormData } from "../../utils/Utils";

/** @type {ColumnHelper<PaymentGatewayType>} */
const columnHelper = createColumnHelper();

/**
 * @param {UserStatusType} value
 * @param {BadgeProps} badgeProps
 */
function renderStatusBadge(value, badgeProps) {
    let label = "Inactive";
    let color = "orange";
    if (value) {
        label = "Active";
        color = "teal";
    }

    return (
        <Badge color={color} {...badgeProps}>
            {label}
        </Badge>
    );
}

export default function PaymentGateway() {
    const [filterValues, setFilterValues] = useState();
    const [modalOpened, modalHandlers] = useDisclosure(false);
    /** @type {[PaymentGatewayType|undefined, Dispatch<SetStateAction<PaymentGatewayType|undefined>>]} */
    const [selectPaymentGateway, setSelectPaymentGateway] = useState();
    const dataGridRef = useRef();

    const columns = useMemo(
        () => [
            columnHelper.accessor("title", { header: "Title" }),
            columnHelper.accessor("typeId", {
                header: "Type",
                cell: ({ getValue }) => {
                    return _PAYMENT_GATEWAY_TYPE_OPTIONS.find(i => i.value == getValue()).label
                }
            }),
            columnHelper.accessor("apiKey", { header: "API Key" }),
            columnHelper.accessor("processingFee", { header: "Processing Fee" }),
            columnHelper.accessor("isActive", {
                header: "Status",
                cell: ({ getValue }) => {
                    return renderStatusBadge(getValue());
                },
            })
        ],
        []
    );

    const form = useForm({
        /** @typedef {{ name: string }} initialValues */
        initialValues: {
            name: "",
        },
    });

    /** @param {initialValues} values */
    function onSubmit(values) {
        setFilterValues(values);
    }

    /** @param {string} id @param {UserStatusType} status */
    async function updatePaymentGatewayStatus(id, status) {
        try {
            /** @type {ResponseData} */
            let body = { isActive: status };
            const response = await ApiEngine.put(`${ApiRoutes.PAYMENT_GATEWAY}/${id}`, body);

            if (!response.success) {
                throw response.message;
            }

            showAlert("success", response.message);
            dataGridRef.current?.reload();
        } catch (error) {
            showAlert("fail", error);
        }
    }

    return (
        <>
            <Group>
                <Button leftIcon={<IconPlus size={18} />} onClick={modalHandlers.open}>
                    Add
                </Button>
                <Text size="xl">Payment Gateway</Text>
            </Group>
            <Divider mt="xs" mb="md" />
            <Stack>
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <Group align="flex-end">
                        <Group>
                            <TextInput {...form.getInputProps("name")} label="Name" placeholder="Name" icon={<IconMessage2 size={18} />} />
                        </Group>
                        <Group noWrap spacing="xs" mb={1}>
                            <ActionIcon
                                variant="default"
                                radius="lg"
                                size="lg"
                                onClick={() => {
                                    form.reset();
                                    setFilterValues();
                                }}
                            >
                                <IconRotate size={20} />
                            </ActionIcon>
                            <ActionIcon type="submit" variant="filled" radius="lg" size="lg" color="blue">
                                <IconSearch size={20} />
                            </ActionIcon>
                        </Group>
                    </Group>
                </form>
                <DataGrid
                    api={ApiRoutes.PAYMENT_GATEWAY}
                    columns={columns}
                    filterValues={filterValues}
                    highlightOnHover
                    paginationProps={{ position: "right" }}
                    rowContextMenuOption={{
                        items: (rowData) => [
                            {
                                label: rowData?.isActive ? "Inactivate" : "Activate",
                                icon: rowData?.isActive ? <IconUserX size={18} color="orange" /> : <IconUserCheck size={18} color="teal" />,
                                onClick: () => updatePaymentGatewayStatus(rowData?.id, !rowData?.isActive),
                            },
                            {
                                label: "Edit",
                                icon: <IconEdit size={18} />,
                                onClick: () => {
                                    setSelectPaymentGateway(rowData);
                                    modalHandlers.open();
                                },
                            }
                        ],
                    }}
                    ref={dataGridRef}
                />
            </Stack>
            <CreateUpdateModal
                opened={modalOpened}
                onClose={() => {
                    modalHandlers.close();
                    setSelectPaymentGateway();
                }}
                paymentGateway={selectPaymentGateway}
                successCallback={() => dataGridRef.current?.reload()}
            />
        </>
    );
}

PaymentGateway.getLayout = function getLayout(page) {
    return <Layout>{page}</Layout>;
};
