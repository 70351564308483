export * from "./ApiRoutes";
export * from "./WebRoutes";

export const APP_NAME = "Tipping System";

/// <summary>
/// Author : -
/// API related key or field when making ajax or api call
/// </summary>
export class ApiKey {
    static _API_SUCCESS_KEY = "success";
    static _API_MESSAGE_KEY = "message";
    static _API_DATA_KEY = "data";
    static _API_CODE_KEY = "code";
    static _API_CONTENT_TYPE = 'Content-Type';
    static _API_ACCEPT = "Accept";
    static _API_APPLICATION_JSON = "application/json";
    static _API_XML_HTTP_REQUEST_KEY = "XMLHttpRequest";
    static _API_FORM_URLENCODED = "application/x-www-form-urlencoded";
    static _API_POST = "POST";
    static _API_GET = "GET";

    static _API_INTERNAL_ERROR_CODE = 500;
    static _API_TEMPORARRY_REDIRECT_CODE = 307;
}

/**
 * @typedef {Object} UserType
 * @property {string} id
 * @property {string} username
 * @property {string|File} profileImage
 * @property {number} roleId
 * @property {number} balance
 * @property {string} [referrerId]
 * @property {string} referrerUsername
 * @property {UserStatusType} status
 */

/**
 * @typedef {Object} PostType
 * @property {string} id
 * @property {string} caption
 * @property {{id: string, image: string, sequenceId: string}[]} images
 * @property {string} createdDate
 * @property {UserType} poster
 * @property {GiftType[]} [gifts]
 * @property {Record<string|number, number>} [giftDetails]
 */

/**
 * @typedef {Object} PasswordFormType
 * @property {string} password
 * @property {string} confirmPassword
 */

/**
 * @typedef {Object} GiftType
 * @property {number} id
 * @property {string} name
 * @property {string|File} image
 * @property {number} price
 */

/**
 * @typedef {Object} TransactionHistoryType
 * @property {number} id
 * @property {string} userId
 * @property {string} username
 * @property {string} alias
 * @property {TransactionType} typeId
 * @property {string} typeName
 * @property {string} transactionId
 * @property {string} bankReferenceNumber
 * @property {TransactionStatus} status
 * @property {string} statusName
 * @property {number} initialAmount
 * @property {number} amount
 * @property {number} finalAmount
 * @property {number} cashAmount
 * @property {string} completionTime
 * @property {string} remark
 * @property {string} createdBy
 * @property {string} createdByUsername
 * @property {string} createdDate
 * @property {string} modifiedBy
 * @property {string} modifiedByUsername
 * @property {string} modifiedDate
 */

/**
 * @typedef {Object} ReferrerTopUpType
 * @property {string} userId
 * @property {string} username
 * @property {number} totalTopUp
 * @property {number} commission
 */

/**
 * @typedef {Object} SettlementType
 * @property {string} month
 * @property {string} shopName
 * @property {string} invoiceNumber
 * @property {string} status
 * @property {SettlementStatus} status
 */

/** @enum {number} */
export const PaymentGatewayType = {
    ONLINE_BANKING: 1,
    DUITNOW_QR: 2,
    SUREPAY: 3
};

export const _PAYMENT_GATEWAY_TYPE_OPTIONS = [
    { value: PaymentGatewayType.ONLINE_BANKING, label: "Online Banking" },
    { value: PaymentGatewayType.DUITNOW_QR, label: "DuitNow QR" },
    { value: PaymentGatewayType.SUREPAY, label: "SurePay" }
]

/** @enum {number} */
export const RoleType = {
    SUPER_ADMIN: 1,
    ADMIN: 2,
    SHOP: 3,
    CUSTOMER: 4
};

/** @enum {number} */
export const UserStatusType = {
    ACTIVE: 1,
    INACTIVE: -1,
    DELETED: -2
};

/** @enum {number} */
export const TransactionType = {
    TOP_UP: 1,
    SEND_GIFT: 2,
    REDEEM_GIFT: 3,
    POST_CHARGES: 4,
};

/** @enum {number} */
export const TransactionStatus = {
    PENDING: 0,
    ACCEPTED: 1,
    FAILED: 2,
    PUBLISHED: 3,
    PUBLISH_REJECTED: 4,
    PAID: 5
};

/** @enum {number} */
export const SettlementStatus = {
    PENDING: 0,
    SETTLED: 1,
    REJECTED: 2
};

/// <summary>
/// Author : -
/// </summary>
export class Status {
    static _ENABLED = 1;
    static _DISABLED = 0;
    static _DELETED = 2;
}

/// <summary>
/// Author: -
/// this value needed to be tally to value in server, do not change recklessly
/// </summary> 
export class AccessRight {
    static _PERMISSION_MANAGEMENT = "PERMISSION_MANAGEMENT";
    static _USER_MANAGEMENT = "USER_MANAGEMENT";
    
    static _VIEW_PERMISSION = "read";
    static _CREATE_PERMISSION = "create";
    static _EDIT_PERMISSION = "update";
    static _DELETE_PERMISSION = "delete";
}

/// <summary>
/// Author : -
/// </summary>
export class Language {
    static _CHINESE = "cn";
    static _ENGLISH = "en";
    static _BAHASA_MELAYU = "bm";
}

/// <summary>
/// Author : -
/// </summary>
export class SessionKey {
    static _LANGUAGE = "language";
    static _LOGIN_GUID = "loginGuid";
}

/// <summary>
/// Author: -
/// </summary>
export class InputValidationKey {
    static _REQUIRED_KEY = "required";
    static _MAX_LENGTH_KEY = "maxLength";
    static _MIN_LENGTH_KEY = "minLength";
    static _MIN_KEY = "min";
    static _MAX_KEY = "max";
    static _PATTERN = "pattern";
}

/// <summary>
/// Author: -
/// </summary>
export class AlertTypes {
    static _DEFAULT = 'default';
    static _INFO = 'info';
    static _SUCCESS = 'success';
    static _WARNING = 'warning';
    static _DANGER = 'danger';
    static _ERROR = 'error';
    static _INPUT = 'input';
    static _CUSTOM = 'custom';
}

/// <summary>
/// Author: -
/// </summary>
export class ValidationPattern {
    static _emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    static _phoneNumberRegex = /^(\+?6?01)[0|1|2|3|4|6|7|8|9]\-*[0-9]{7,8}$/;
    static _numberRegex = /^([1-9]|[1-9][0-9]|[1-9][0-9]*)$/;
    static _decimalRegex = /^[1-9][0-9]*(\.\d{1,2})?$|^[0](\.\d{1,2})?$/;
    static _digitRegex = /^\d+$/;
    static _twoDecimalAndLargerThanZero = /^(?!00?\.00$)\d{1,2}(?:\.\d{1,2})?$/;
}

/// <summary>
/// Author: -
/// </summary>
export const _SELECT_STATUS_OPTIONS = [
    { value: false, label: "DISABLED" },
    { value: true, label: "ENABLED" }
]

/// <summary>
/// Author: -
/// </summary>
export class Role {
    static _SUB_ACCOUNT = -1;
    static _SUPER_ADMIN = 1;
    static _COMPANY = 2;
    static _SHAREHOLDER = 3;
    static _ADMIN = 4;
    static _AGENT = 5;
    static _MEMBER = 6;
}

/// <summary>
/// Author: -
/// </summary>
export class LocalStorageKey {
}

/// <summary>
/// Author: -
/// </summary>
export const _LOCAL_STORAGE_DISPATCH = [

]

/// <summary>
/// Author: -
/// </summary>
export const _SELECT_DEBOUNCE_MS = 50;


/// <summary>
/// Author: CK
/// </summary>
export const _SELECT_TRANSACTION_STATUS_OPTIONS = [
    { value: TransactionStatus._PENDING, label: "PENDING" },
    { value: TransactionStatus._APPROVED, label: "APPROVED" },
    { value: TransactionStatus._REJECTED, label: "REJECTED" }
]

/// <summary>
/// Author : CK
/// </summary>
export class TippingType {
    static _CUSTOM = 1;
    static _PRESET = 2;
    static _TIPPING = 3;
  }