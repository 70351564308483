export const ApiRoutes = {
    BASE_URL: "https://tip-admin-api.mpw-test.com/",
    LOGIN: "/account/login",
    IS_AUTHORISED: "/account/is-authorised",

    USER: "/user",
    USER_LIST: "/user/customer",
    USER_DETAIL: "/user/customer/{id}",
    USER_STATUS: "/user/status",
    USER_PASSWORD: "/user/password",

    SHOP: "/user/shop",
    BRANCH_CATEGORY: "/branch/staff/categories",
    BRANCH_SUB_CATEGORY: "/branch/staff/sub-categories?parentId=",
    BRANCH: "/branch",
    TABLE: "/branch/table",
    ADMIN: "/user/admin",
    STAFF: "/branch/staff",

    GIFT: "/branch/gift",
    MESSAGE_TEMPLATE: "/branch/message-template",
    PAYMENT_GATEWAY: "/configuration/payment-gateway",

    REPORT_TRANSACTION_HISTORY: "/report/transaction/history",
    REPORT_REFERRER_TOP_UP_COMMISSION: "/report/referrer/topup/commission",
    REPORT_TIPPING: "/report/tipping",
    UPDATE_TIPPING_STATUS: "/report/tipping/status",

    SETTLEMENT_HISTORY: "/report/settlement",
    UPDATE_SETTLEMENT_STATUS: "/report/settlement/status",
    PENDING_SETTLEMENT_SUM: "/report/settlement/pending-sum",
    APPROVE_PENDING_SETTLEMENT: "/report/settlement/generate",

    LOGOUT: "/account/logout"
};
