import { createStyles } from "@mantine/core";

export default createStyles((theme, params, getRef) => ({
    tableContainer: {
        ".tableWrapper": {
            border: `1px solid ${theme.colors.gray[4]}`,
            borderRadius: theme.radius.sm,
            overflow: "hidden",

            thead: {
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "#ffffff",
                boxShadow: `0 1px 0 0 ${theme.colors.gray[4]}`,
            },

            tbody: {
                tr: {
                    "&.active": {
                        backgroundColor: theme.fn.darken("#f1f3f5", 0.05),
                    },
                },
            },

            ".mantine-ScrollArea-root": {
                maxHeight: "68vh",

                ".mantine-ScrollArea-viewport": {
                    maxHeight: "inherit",
                },

                ".mantine-ScrollArea-scrollbar": {
                    "&[data-orientation=vertical]": {
                        top: `${params?.headerHeight ?? 0}px !important`,
                    },
                },
            },
        },
        ".paginationContainer": {
            marginTop: theme.spacing.md,
        },
    },
    noDataRow: {
        ".icon, .mantine-Text-root": {
            fontWeight: "bold",
            fontSize: theme.fontSizes.lg,
            color: theme.colors.gray[4],
        },
    },
}));
