import { Box, Group, Pagination as MantinePagination, PaginationProps, Select, createStyles } from "@mantine/core";
import { Table } from "@tanstack/react-table";

export const DefaultPaginationState = {
    pageIndex: 0,
    pageSize: 50,
};

const DefaultPageSizeOptions = [10, 25, 50, 100];

/** @param {number[]} values */
function parsePageSizeOptions(values) {
    return values.map((value) => ({ label: `${value} / page`, value: `${value}` }));
}

const useStyles = createStyles((theme, params, getRef) => ({
    paginationContainer: {},
    select: {
        flex: "0 0 110px",
    },
}));

/**
 * @param {{table: Table<unknow>} & PaginationProps & {pageSizeOptions?: number[]}} props
 */
export default function Pagination({ table, pageSizeOptions = DefaultPageSizeOptions, position, hidden, ...rest }) {
    const { classes, cx } = useStyles();

    return (
        <Group className={cx(classes.paginationContainer, "paginationContainer")} position={position} hidden={hidden}>
            <MantinePagination total={table.getPageCount() > 0 ? table.getPageCount() : 1} page={table.getState().pagination.pageIndex + 1} onChange={(pageNum) => table.setPageIndex(pageNum - 1)} {...rest} />
            <Select className={classes.select} data={parsePageSizeOptions(DefaultPageSizeOptions)} value={`${table.getState().pagination.pageSize}`} onChange={(value) => table.setPageSize(Number(value))} />
        </Group>
    );
}
