import { ActionIcon, Badge, Divider, Grid, Group, Loader, Select, Stack, Text, TextInput } from "@mantine/core";
import { DateRangePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDebouncedState, useDidUpdate, useDisclosure } from "@mantine/hooks";
import { IconArrowsUpDown, IconCalendar, IconCashBanknote, IconClipboardCheck, IconIdBadge2, IconRotate, IconSearch, IconUser } from "@tabler/icons";
import { ColumnHelper, createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useMemo, useRef, useState, useEffect } from "react";
import { DataGrid } from "../../components/elements/DataGrid";
import Layout from "../../components/layout";
import TransactionHistoryDetailDrawer, { renderStatusBadge } from "../../components/report/TransactionHistoryDetailDrawer";
import { APP_NAME, ApiRoutes, RoleType, TransactionHistoryType, TransactionStatus, TransactionType, TippingType } from "../../constants";
import ApiEngine, { ResponseData } from "../../utils/ApiEngine";
import { constructQueryString, showAlert, stringIsNullOrEmpty } from "../../utils/Utils";
import { useDispatch, useSelector } from 'react-redux';

/** @type {ColumnHelper<TransactionHistoryType>} */
const columnHelper = createColumnHelper();

const transactionTypeOpts = Object.entries(TransactionType).map(([key, value]) => ({ label: key, value: `${value}` }));
const transactionStatusOpts = Object.entries(TransactionStatus).map(([key, value]) => ({ label: key, value: `${value}` }));

export default function Transaction() {
    const [filterValues, setFilterValues] = useState();
    const [detailDrawerOpened, detailDrawerHandlers] = useDisclosure(false);
    /** @type {[TransactionHistoryType|undefined, Dispatch<SetStateAction<TransactionHistoryType|undefined>>]} */
    const [selectTransaction, setSelectTransaction] = useState();
    const [searchBranch, setSearchBranch] = useDebouncedState("", 300);
    const [searchBranchLoading, setSearchBranchLoading] = useState(false);
    const [branchOpts, setBranchOpts] = useState([]);
    const dataGridRef = useRef();
    var _userData = useSelector((state) => state.authState.userData);

    const columns = useMemo(
        () => [
            columnHelper.accessor((row) => (row.modifiedDate ? dayjs(row.modifiedDate).format("DD/MM/YYYY hh:mm:ss A") : ""), { header: "Completion Time" }),
            columnHelper.accessor("transactionId", { header: "Transaction ID" }),
            columnHelper.accessor("shopName", { header: "Shop" }),
            columnHelper.accessor("branchName", { header: "Branch" }),
            columnHelper.accessor("type", { header: "Type" }),
            columnHelper.accessor("sender", {
                header: "Sender",
                cell: ({ getValue, row }) => {
                    return `${row.original?.nickname ?? '🤷🏻🤷🏻‍♀️'}`;
                },
            }),
            columnHelper.accessor("message", {
                header: "Tip Description",
                cell: ({ getValue, row }) => {
                    return `${row.original.typeId == TippingType._TIPPING ? row.original.receiverStaffName : row.original.message}`;
                },
            }),
            columnHelper.accessor((row) => row.amount.toFixed(2), { header: "Amount" }),
            columnHelper.accessor("status", {
                header: "Status",
                cell: ({ getValue, row }) => renderStatusBadge(getValue()),
            }),
            columnHelper.accessor("publishRemark", {
                header: "Remark",
                cell: ({ getValue, row }) => stringIsNullOrEmpty(getValue()) ? "-" : getValue(),
            })
        ],
        []
    );

    const form = useForm({
        initialValues: {
            status: "",
            transactionId: "",
            branchId: "",
            transactionTypeId: "",
            dateRange: [null, null],
        },
    });

    useEffect(() => {
        form.reset();
        searchBranchOpts("");
    }, []);

    /** @param {Partial<TransactionHistoryType> & {dateRange?: [Date, Date], startDate?: Date, endDate?: Date}} values */
    function onSubmit(values) {
        if (values.dateRange && values.dateRange.every((d) => d)) {
            values.startDate = dayjs(values.dateRange[0]).format("YYYY-MM-DD HH:mm:ss");
            values.endDate = dayjs(values.dateRange[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss");
        }
        delete values.dateRange;
        setFilterValues(values);
    }

    async function searchBranchOpts(value) {
        try {
            setSearchBranchLoading(true);

            let url = constructQueryString(ApiRoutes.BRANCH, { shopId: _userData.roleId > RoleType.ADMIN ? _userData.id : '', name: !stringIsNullOrEmpty(value) ? value : "" });
            /** @type {ResponseData} */
            const response = await ApiEngine.get(url);

            if (!response.success) {
                throw response.message;
            }

            let data = response.data ?? [];

            setBranchOpts(data.map(({ id, name }) => ({ label: name, value: id })));
        } catch (error) {
            showAlert("fail", error);
        } finally {
            setSearchBranchLoading(false);
        }
    }

    return (
        <>
            <Text size="xl">Transaction History</Text>
            <Divider mt="xs" mb="md" />
            <Stack>
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <Grid>
                        <Grid.Col>
                            <Group>
                                <DateRangePicker
                                    inputFormat="DD/MM/YYYY"
                                    labelFormat="DD/MM/YYYY"
                                    {...form.getInputProps("dateRange")}
                                    label="Date"
                                    placeholder="Pick Date Range"
                                    icon={<IconCalendar size={18} />}
                                    allowSingleDateInRange
                                    firstDayOfWeek="sunday"
                                />
                                <TextInput {...form.getInputProps("transactionId")} label="Transaction ID" icon={<IconIdBadge2 size={18} />} placeholder="Transaction ID" />
                                <Select
                                    {...form.getInputProps("branchId")}
                                    label="Branch"
                                    placeholder="Branch"
                                    icon={<IconUser size={18} />}
                                    data={branchOpts}
                                    searchable
                                    onSearchChange={(value) => {
                                        if (!stringIsNullOrEmpty(form.values.branchId)) {
                                            const opt = branchOpts.find(({ value }) => value === form.values.branchId);
                                            opt?.label !== value && setSearchBranch(value);
                                        } else {
                                            if (branchOpts.length > 0) {
                                                setSearchBranch(branchOpts[0].value);
                                                form.setFieldValue("branchId", branchOpts[0].value);
                                            }
                                            else {
                                                setSearchBranch(value);
                                                form.setFieldValue("branchId", "");
                                            }
                                        }
                                    }}
                                    nothingFoundMessage="Nothing found..."
                                    rightSection={searchBranchLoading && <Loader size="xs" />}
                                />
                                <Select {...form.getInputProps("status")} label="Status" placeholder="Status" icon={<IconClipboardCheck size={18} />} data={transactionStatusOpts} clearable />
                                <Group noWrap spacing="xs" sx={{ alignSelf: "flex-end" }} mb={1}>
                                    <ActionIcon
                                        variant="default"
                                        radius="lg"
                                        size="lg"
                                        onClick={() => {
                                            form.reset();
                                            setFilterValues();
                                        }}
                                    >
                                        <IconRotate size={20} />
                                    </ActionIcon>
                                    <ActionIcon type="submit" variant="filled" radius="lg" size="lg" color="blue">
                                        <IconSearch size={20} />
                                    </ActionIcon>
                                </Group>
                            </Group>
                        </Grid.Col>
                    </Grid>
                </form>
                <DataGrid
                    api={ApiRoutes.REPORT_TIPPING}
                    columns={columns}
                    filterValues={filterValues}
                    highlightOnHover
                    paginationProps={{ position: "right" }}
                    ref={dataGridRef}
                // onRowClick={(rowData) => {
                //     setSelectTransaction(rowData);
                //     detailDrawerHandlers.open();
                // }}
                />
                <TransactionHistoryDetailDrawer
                    opened={detailDrawerOpened}
                    onClose={() => {
                        detailDrawerHandlers.close();
                        setSelectTransaction();
                    }}
                    data={selectTransaction}
                />
            </Stack>
        </>
    );
}

Transaction.getLayout = function getLayout(page) {
    return <Layout>{page}</Layout>;
};
