import { Avatar, Box, Button, FileButton, Grid, Group, Modal, ModalProps, NumberInput, PasswordInput, Select, Stack, TextInput, createStyles } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconArrowUpCircle } from "@tabler/icons";
import { useEffect, useState } from "react";
import { ApiRoutes, PasswordFormType, RoleType, UserType } from "../../constants";
import ApiEngine, { ResponseData } from "../../utils/ApiEngine";
import { getFormData, showAlert, stringIsNullOrEmpty } from "../../utils/Utils";

/**
 * @param {ModalProps} props
 */
export function ChangePasswordModal(props) {
    const { opened, centered = true, ...restProps } = props;

    const form = useForm({
        /** @typedef {{oldPassword: string, confirmPassword: string, newPassword: string}} initialValues */
        initialValues: {
            oldPassword: "",
            confirmPassword: "",
            newPassword: "",
        },
        validate: {
            oldPassword: (value) => (value === "" ? true : undefined),
            confirmPassword: (value, values) => (value === "" || value !== values.oldPassword ? true : undefined),
            newPassword: (value) => (value === "" ? true : undefined),
        },
    });

    /** @param {initialValues} values */
    async function onSubmit(values) {
        try {
            /** @type {ResponseData} */
            const response = await ApiEngine.put(ApiRoutes.USER_PASSWORD, values);

            if (!response.success) {
                throw response.message;
            }
            showAlert("success", response.message);
            props.onClose();
        } catch (error) {
            showAlert("fail", error);
        }
    }

    useEffect(() => {
        if (opened) {
            form.reset();
        }
    }, [opened]);

    return (
        <Modal opened={opened} centered={centered} title="Change Password" {...restProps}>
            <form onSubmit={form.onSubmit(onSubmit)}>
                <Stack>
                    <PasswordInput {...form.getInputProps("oldPassword")} label={"Old Password"} placeholder={"Old Password"} withAsterisk />
                    <PasswordInput {...form.getInputProps("confirmPassword")} label={"Confirm Password"} placeholder={"Confirm Password"} withAsterisk />
                    <PasswordInput {...form.getInputProps("newPassword")} label={"New Password"} placeholder={"New Password"} withAsterisk />
                    <Group position="right">
                        <Button variant="default" onClick={props.onClose}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="filled">
                            Save
                        </Button>
                    </Group>
                </Stack>
            </form>
        </Modal>
    );
}
