import { Button, Switch, Grid, Group, Modal, Select, Stack, TextInput, createStyles, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconArrowUpCircle } from "@tabler/icons";
import { useEffect, useState } from "react";
import { ApiRoutes, RoleType } from "../../constants";
import ApiEngine from "../../utils/ApiEngine";
import { getFormData, showAlert, stringIsNullOrEmpty, isObjectEmpty } from "../../utils/Utils";
import { useSelector } from "react-redux";

const useStyles = createStyles((theme, params, getRef) => ({
    avatar: {
        objectFit: "contain",
        backgroundColor: "white",
        border: "2px solid",
        borderColor: theme.colorScheme === "dark" ? "#FFFFFF" : "#000000",
        borderRadius: theme.radius.md,
        width: 100,
        height: 100,
    },
    avatarContainer: {
        position: "relative",
        cursor: "pointer",
        width: "fit-content",
        margin: "auto",

        ".icon.icon-tabler-arrow-up-circle": {
            background: "white",
            position: "absolute",
            bottom: -5,
            left: 0,
            right: -85,
            margin: "auto",
            borderRadius: theme.radius.xl,
            height: 32,
            width: 32,
        },
    },
}));

export default function CreateUpdateModal(props) {
    const { opened, centered = true, user: userProp, successCallback, ...restProps } = props;
    const { classes } = useStyles();
    const [user, setUser] = useState({});
    const [branchOption, setBranchOption] = useState([]);
    const userData = useSelector((state) => state.authState.userData);
    const [checkedActive, setCheckedActive] = useState(false);
    const form = useForm({
        initialValues: {
            branchId: "",
            tableName: "",
            isActive: false
        },
        validate: {
            branchId: (value) => (value === "" ? true : undefined),
            tableName: (value) => (value === "" ? true : undefined)
        },
    });

    async function onSubmit(values) {
        let url = ApiRoutes.TABLE;
        let body = getFormData({ ...values, isActive: checkedActive });
        let response;

        try {
            if (isObjectEmpty(user)) {
                response = await ApiEngine.post(url, body);
            }
            else {
                response = await ApiEngine.put(`${url}/${user.id}`, body);
            }

            if (!response.success) {
                throw response.message;
            }

            showAlert("success", response.message);
            typeof successCallback === "function" && successCallback(values);
            props.onClose();
        } catch (error) {
            showAlert("fail", error);
        }
    }

    useEffect(() => {
        if (opened) {
            form.reset();
            getBranchOption();

            if (userProp) {
                setUser({ ...userProp });
                setCheckedActive(userProp.isActive);
                form.setValues({ ...userProp });
            } else {
                setUser({});
            }
        }
    }, [opened]);

    async function getBranchOption() {
        try {
            let url = `${ApiRoutes.BRANCH}?isActive=true`;

            if (userData.roleId > RoleType.ADMIN) {
                url += `&shopId=${userData.id}`
            }

            const response = await ApiEngine.get(url);

            if (!response.success) {
                throw response.message;
            }

            setBranchOption(response.data.map(branch => { return { label: `${branch.name.toUpperCase()} (${stringIsNullOrEmpty(branch?.addressDetail?.city) ? '-' : branch?.addressDetail?.city})`, value: branch.id } }));
        } catch (error) {
            showAlert("fail", error);
        }
    }

    return (
        <Modal opened={opened} size={user ? "md" : "lg"} centered={centered} title={"Form"} {...restProps}>
            <form onSubmit={form.onSubmit(onSubmit)}>
                <Stack>
                    <Grid>
                        <Grid.Col xs={12}>
                            <Select {...form.getInputProps("branchId")} label={"Branch"} placeholder={"Branch"} data={branchOption} withAsterisk />
                        </Grid.Col>
                        <Grid.Col xs={12}>
                            <TextInput {...form.getInputProps("tableName")} label={"Table Name"} placeholder={"Table Name"} withAsterisk />
                        </Grid.Col>
                        <Grid.Col xs={6}>
                            <Text mb={1}>Active</Text>
                            <Switch checked={checkedActive} onChange={(event) => setCheckedActive(event.currentTarget.checked)} />
                        </Grid.Col>
                    </Grid>
                    <Group position="right">
                        <Button variant="default" onClick={props.onClose}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="filled">
                            Save
                        </Button>
                    </Group>
                </Stack>
            </form>
        </Modal>
    );
}
