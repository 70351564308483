import { createFormBody, showAlert, stringIsNullOrEmpty } from "../utils/Utils";
import { ApiKey, AlertTypes } from "../constants";
import { ApiRoutes } from "../constants/ApiRoutes";
import { setBusy, setIdle } from "./AppAction";
import ApiEngine, { ResponseData } from "../utils/ApiEngine";
export const _LOGIN_SUCCESS = 'LoginSuccess';
export const _LOGIN_FAILED = 'LoginFailed';
export const _LOGOUT = 'Logout';
export const _ACTION_FORBIDDEN = 'ActionForbidden';

/// <summary>
/// Author : -
/// expose login action as function to be called from classes
/// </summary>
export function performLogin(username, password) {
    return async dispatch => {
        dispatch({
            type: _LOGOUT
        });

        let params = {
            clientId: "back_office_client_id_0123456789",
            clientSecret: "back_office_client_secret_0123456789",
            "username": username,
            "password": password,
        };

        dispatch(setBusy());

        const response = await ApiEngine.post(ApiRoutes.LOGIN, params);

        if (response[ApiKey._API_SUCCESS_KEY]) {
            dispatch({
                type: _LOGIN_SUCCESS,
                userData: response[ApiKey._API_DATA_KEY]?.user,
                token: response[ApiKey._API_DATA_KEY]?.token
            });
        }
        else {
            dispatch({
                type: _LOGIN_FAILED,
                message: response[ApiKey._API_MESSAGE_KEY],
            });

            showAlert((response[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR), response[ApiKey._API_MESSAGE_KEY]);
        }
        
        dispatch(setIdle());
    }
}

/// <summary>
/// Author : -
/// this function is to only update user status to login if it is already considered authenticated in cookie
/// </summary>
export const updateLoginUser = (userData, token) => {
    if (stringIsNullOrEmpty(userData) || stringIsNullOrEmpty(token)) {
        return {
            type: _LOGIN_FAILED
        }
    }
    else {
        return {
            type: _LOGIN_SUCCESS,
            userData: userData,
            token: token
        }
    }
    
}

/// <summary>
/// Author : -
/// expose logout action as function to be called from classes
/// </summary>
export const performLogout = () => {
    return async dispatch => {
        await ApiEngine.post(ApiRoutes.LOGOUT);

        dispatch({
            type: _LOGOUT
        });
    }
}

/// <summary>
/// Author : -
/// this function is to only update user status to logout
/// </summary>
export const resetLogin = () => {
    return async dispatch => {
        dispatch({
            type: _LOGOUT
        });
    }
}