import { useEffect } from "react";
import { ButtonStylesParams, MantineProvider, LoadingOverlay } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { NotificationsProvider } from "@mantine/notifications";
import dayjs from "dayjs";
import Content from './routes/content';
import { useRouter } from "./hooks/useRouter";
import { Config, ConfigEnum } from "./utils/Config";
import { APP_NAME, ApiRoutes, WebRoutes } from "./constants";
import { updateLoginUser } from './redux/AuthAction';
import { useDispatch, useSelector } from 'react-redux';
dayjs.extend(require("dayjs/plugin/localizedFormat"));

function App() {
  const router = useRouter();
  var _dispatch = useDispatch();
  var loginStateInitialized = useSelector((state) => state.authState.loginStateInitialized);
  var _isBusy = useSelector((state) => state.appState.isBusy);

  /// <summary>
  /// Author: CK
  /// </summary>
  useEffect(() => {
    (async () => {
      let userData = Config.getInstance().getValue(ConfigEnum.USER);
      let token = Config.getInstance().getValue(ConfigEnum.TOKEN);

      if (userData !== null && token !== null) {
        await _dispatch(updateLoginUser(userData, token));
      }
      else {
        await _dispatch(updateLoginUser(null, null));
      }

      if (userData === null) {
        router.navigate(WebRoutes.LOGIN);
      }
    })();
  }, []);

  return (
    <>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          globalStyles: (theme) => ({
            body: {
              backgroundColor: "#2e3b61",
            },
          }),
          components: {
            Button: {
              /** @param {ButtonStylesParams} params */
              styles: (theme, params) => {
                let result = {};

                if (params.variant === "filled") {
                  result.root = {
                    ...result.root,
                    backgroundColor: theme.fn.lighten("#2e3b61", 0.1),

                    "&:hover": {
                      backgroundColor: "#2e3b61",
                    },

                    "&:disabled": {
                      backgroundColor: "#2e3b61",
                      color: "white",
                    },
                  };
                }

                return result;
              },
            },
          },
          defaultRadius: "md",
        }}
      >
        <ModalsProvider>
          <NotificationsProvider>
            {
              loginStateInitialized &&
              <Content />
            }
            <LoadingOverlay visible={_isBusy} />
          </NotificationsProvider>
        </ModalsProvider>
      </MantineProvider>
    </>
  );
}

export default App;
