import { Avatar, Box, Button, FileButton, Grid, Group, Modal, NumberInput, PasswordInput, Stack, TextInput, createStyles } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconArrowUpCircle } from "@tabler/icons";
import { useEffect, useState } from "react";
import { ApiRoutes } from "../../constants";
import ApiEngine from "../../utils/ApiEngine";
import { getFormData, showAlert, stringIsNullOrEmpty, isObjectEmpty } from "../../utils/Utils";

const useStyles = createStyles((theme, params, getRef) => ({
    avatar: {
        objectFit: "contain",
        backgroundColor: "white",
        border: "2px solid",
        borderColor: theme.colorScheme === "dark" ? "#FFFFFF" : "#000000",
        borderRadius: theme.radius.md,
        width: 100,
        height: 100,
    },
    avatarContainer: {
        position: "relative",
        cursor: "pointer",
        width: "fit-content",
        margin: "auto",

        ".icon.icon-tabler-arrow-up-circle": {
            background: "white",
            position: "absolute",
            bottom: -5,
            left: 0,
            right: -85,
            margin: "auto",
            borderRadius: theme.radius.xl,
            height: 32,
            width: 32,
        },
    },
}));

export default function CreateUpdateModal(props) {
    const { opened, centered = true, user: userProp, successCallback, ...restProps } = props;
    const { classes } = useStyles();
    const [user, setUser] = useState({});

    const form = useForm({
        initialValues: {
            username: "",
            password: "",
            confirmPassword: "",
            processingFee: 0
        },
        validate: {
            ...(userProp
                ? {}
                : {
                    password: (value) => (value === "" ? true : undefined),
                    confirmPassword: (value, values) => (value === "" || value !== values.password ? true : undefined),
                }),
            username: (value) => (value === "" ? true : undefined),
            processingFee: (value) => (typeof value === "undefined" ? true : undefined),
        },
    });
    const [file, setFile] = useState();
    const [imageUrl, setImageUrl] = useState();

    async function onSubmit(values) {
        if (file) {
            values.picture = file;
        }

        let url = ApiRoutes.SHOP;
        let body = getFormData(values);
        let response;

        try {
            if (isObjectEmpty(user)) {
                response = await ApiEngine.post(url, body);
            }
            else {
                if (values.password !== values.confirmPassword) {
                    throw 'Password mismatch';
                }

                response = await ApiEngine.put(`${url}/${user.id}`, body);
            }

            if (!response.success) {
                throw response.message;
            }

            showAlert("success", response.message);
            typeof successCallback === "function" && successCallback(values);
            props.onClose();
        } catch (error) {
            showAlert("fail", error);
        }
    }

    useEffect(() => {
        file ? setImageUrl(URL.createObjectURL(file)) : setImageUrl();
    }, [file]);

    useEffect(() => {
        return () => imageUrl && URL.revokeObjectURL(imageUrl);
    }, [imageUrl]);

    useEffect(() => {
        if (opened) {
            setFile();
            form.reset();
            if (userProp) {
                setUser({ ...userProp });
                form.setValues({ ...userProp });
            } else {
                setUser({});
            }
        }
    }, [opened]);

    return (
        <Modal opened={opened} size={user ? "md" : "lg"} centered={centered} title={"Form"} {...restProps}>
            <form onSubmit={form.onSubmit(onSubmit)}>
                <Stack>
                    <FileButton onChange={setFile} accept="image/png,image/jpeg">
                        {(fileInputProps) => (
                            <Box {...fileInputProps} className={classes.avatarContainer}>
                                <Avatar className={classes.avatar} src={imageUrl ? imageUrl : !stringIsNullOrEmpty(user?.profilePicture) ? user.profilePicture : null} />
                                <IconArrowUpCircle />
                            </Box>
                        )}
                    </FileButton>
                    <Grid>
                        <Grid.Col xs={12}>
                            <Stack>
                                <TextInput {...form.getInputProps("username")} label={"Username"} placeholder={"Username"} disabled={!isObjectEmpty(user)} withAsterisk />
                                <PasswordInput {...form.getInputProps("password")} label={"Password"} placeholder={"Password"} withAsterisk />
                                <PasswordInput {...form.getInputProps("confirmPassword")} label={"Confirm Password"} placeholder={"Confirm Password"} withAsterisk />
                                <NumberInput {...form.getInputProps("processingFee")} label={"Processing Fee"} placeholder={"Processing Fee"} hideControls min={0} precision={2} withAsterisk />
                            </Stack>
                        </Grid.Col>

                    </Grid>
                    <Group position="right">
                        <Button variant="default" onClick={props.onClose}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="filled">
                            Save
                        </Button>
                    </Group>
                </Stack>
            </form>
        </Modal>
    );
}
