import { Badge, Stack, Button, Divider, Group, Text, Modal, Box } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconEdit, IconPlus, IconUserX, IconUserCheck, IconQrcode } from "@tabler/icons";
import { ColumnHelper, createColumnHelper } from "@tanstack/react-table";
import { useMemo, useRef, useState, createRef } from "react";
import { DataGrid } from "../../components/elements/DataGrid";
import Layout from "../../components/layout";
import CreateUpdateModal from "../../components/table/CreateUpdateModal";
import { ApiRoutes, UserStatusType } from "../../constants";
import ApiEngine from "../../utils/ApiEngine";
import { showAlert, getFormData, isObjectEmpty } from "../../utils/Utils";
import QRCode from "react-qr-code";
import { useScreenshot, createFileName } from "use-react-screenshot";

function renderStatusBadge(value, badgeProps) {
    let label = "Inactive";
    let color = "orange";
    if (value === true) {
        label = "Active";
        color = "teal";
    }

    return (
        <Badge color={color} {...badgeProps}>
            {label}
        </Badge>
    );
}

const columnHelper = createColumnHelper();

export default function Table() {
    const [modalOpened, modalHandlers] = useDisclosure(false);
    const [qrValue, setQrValue] = useState();
    const [selectedBranch, setSelectedBranch] = useState();
    const dataGridRef = useRef();
    const ref = createRef(null);
    const [image, takeScreenShot] = useScreenshot({
        type: "image/jpeg",
        quality: 1.0
    });

    const columns = useMemo(
        () => [
            columnHelper.accessor("branchName", {
                header: "Branch",
                cell: ({ getValue }) => {
                    return getValue();
                },
            }),
            columnHelper.accessor(
                "tableName",
                {
                    header: "Table",
                    cell: ({ row, getValue }) => {
                        return <Text>{getValue()}</Text>
                    }
                }
            ),
            columnHelper.accessor("isActive", {
                header: "Status",
                cell: ({ getValue }) => {
                    return renderStatusBadge(getValue());
                },
            }),
        ],
        []
    );

    async function updateBranchStatus(id, status) {
        try {
            const response = await ApiEngine.put(`${ApiRoutes.TABLE}/${id}`, getFormData({ isActive: status }));

            if (!response.success) {
                throw response.message;
            }

            showAlert("success", response.message);
            dataGridRef.current?.reload();
        } catch (error) {
            showAlert("fail", error);
        }
    }

    const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

    const download = (image, { name = `${qrValue?.tableName}-QR`, extension = "jpg" } = {}) => {
        const a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
    };

    return (
        <>
            <Group>
                <Button leftIcon={<IconPlus size={18} />} onClick={modalHandlers.open}>
                    Add
                </Button>
                <Text size="xl">Table</Text>
            </Group>
            <Divider mt="xs" mb="md" />
            <Stack>
                <DataGrid
                    api={ApiRoutes.TABLE}
                    columns={columns}
                    highlightOnHover
                    paginationProps={{ position: "right" }}
                    rowContextMenuOption={{
                        items: (rowData) => [
                            {
                                label: rowData?.isActive === true ? "Inactivate" : "Activate",
                                icon: rowData?.isActive === true ? <IconUserX size={18} color="orange" /> : <IconUserCheck size={18} color="teal" />,
                                onClick: () => updateBranchStatus(rowData?.id, rowData?.isActive === true ? false : true),
                            },
                            {
                                label: "Edit",
                                icon: <IconEdit size={18} />,
                                onClick: () => {
                                    setSelectedBranch({ ...rowData });
                                    modalHandlers.open();
                                },
                            },
                            {
                                label: "Generate QR",
                                icon: <IconQrcode size={18} />,
                                onClick: () => {
                                    setQrValue({ ...rowData });
                                },
                            }
                        ],
                    }}
                    ref={dataGridRef}
                />
            </Stack>
            <CreateUpdateModal
                opened={modalOpened}
                onClose={() => {
                    modalHandlers.close();
                    setSelectedBranch();
                }}
                user={selectedBranch}
                successCallback={() => dataGridRef.current?.reload()}
            />
            <Modal opened={!isObjectEmpty(qrValue)} onClose={() => {
                setQrValue({});
            }} title={`${qrValue?.tableName} QR`}>
                <Stack>
                    <Box ref={ref} p={5}>
                        {
                            !isObjectEmpty(qrValue) &&
                            <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={qrValue?.qr}
                                viewBox={`0 0 256 256`} />
                        }
                    </Box>
                    {
                        qrValue?.qr &&
                        <a href={qrValue?.qr} target="_blank">{qrValue?.qr}</a>    
                    }
                    <Group position="right">
                        <Button variant="default" onClick={() => setQrValue({})}>
                            Close
                        </Button>
                        <Button variant="filled" onClick={() => downloadScreenshot()}>
                            Download
                        </Button>
                    </Group>
                </Stack>
            </Modal>
        </>
    );
}

Table.getLayout = function getLayout(page) {
    return <Layout>{page}</Layout>;
};
