export const WebRoutes = {
    MAIN: "/",
    LOGIN: "/login",
    DASHBOARD: "/dashboard",
    ADMINS: "/admins",
    USERS: "/users",
    SHOP: "/shops",
    BRANCH: "/branch",
    STAFFS: "/staffs",
    POSTS: "/posts",
    GIFTS: "/gifts",
    MESSAGE_TEMPLATE: "/message-template",
    REPORT_TRANSACTION_HISTORY: "/report/transaction-history",
    REPORT_REFERRER_TOP_UP: "/report/referrer-top-up",
    CONFIGURATION_PAYMENT_GATEWAY: "/configuration/payment-gateway",
    TABLE: "/table",
    SETTLEMENT: "/report/settlement",
};
