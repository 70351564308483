import { PhoneNumberType } from 'google-libphonenumber';
import { NotificationProps, showNotification } from "@mantine/notifications";
import { InputValidationKey, _LOCAL_STORAGE_DISPATCH } from '../constants';

/// <summary>
/// Author: KurisuCodes
/// </summary>
export function stringIsNullOrEmpty(data) {
    return data === null || data === "" || data === undefined;
}

/**
 * @param {'success'|'fail'} type
 * @param {NotificationProps|string} notificationProps
 */
export function showAlert(type, notificationProps) {
    let props = {};
    if (type === "success") {
        props.title = "Successful";
        props.color = "teal";
    } else if (type === "fail") {
        props.title = "Oops";
        props.color = "red";
    }

    showNotification({
        ...props,
        ...(typeof notificationProps === "string" ? { message: notificationProps } : notificationProps),
    });
}

/**
 * @param {string} url
 * @param {Object} filterObj
 * @returns {string}
 */
export function constructQueryString(url, filterObj) {
    if (typeof url === "string" && url !== "" && filterObj) {
        url +=
            (url.includes("?") ? "&" : "?") +
            Object.keys(filterObj)
                .filter((key) => filterObj[key] !== undefined)
                .map((key) => key + "=" + encodeURIComponent(filterObj[key]))
                .join("&");
    }

    return url;
}

/**
 * @param {Object} values
 */
export function getFormData(values) {
    return Object.keys(values).reduce((formData, key) => {
        if (values[key] != undefined) {
            formData.append(key, values[key]);
        }

        return formData;
    }, new FormData());
}

/// <summary>
/// Author : -
/// Convert array into form that use to post data
/// </summary>
export function createFormBody(params) {
    let formBody = [];

    for (let property in params) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(params[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }

    formBody = formBody.join("&");
    return formBody;
}

/// <summary>
/// Author : -
/// Convert array into form that use to post data
/// </summary>
export function createMultiPartFormBody(params) {
    var formBody = new FormData();

    for (const [key, value] of Object.entries(params)) {
        formBody.append(key, value);
    }

    return formBody;
}

/// <summary>
/// Author : -
/// Used to Navigation to certain path
/// </summary>
export function navigateTo(history, url, state) {
    if (state !== undefined) {
        history.push({
            pathname: url,
            state: state
        });
    } else {
        history.push(url);
    }
}

/// <summary>
/// Author : -
/// </summary>
export const dataTableFilterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] !== null && typeof row[id] === 'string') {
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
        )
    } else {
        return (
            String(row[filter.id]) === filter.value
        )
    }
}

/// <summary>
/// Author: -
/// </summary>
export function getValidationMessage(error, patternErrorTxt = '') {
    var validationMsg = "";

    switch (error.type) {
        case InputValidationKey._REQUIRED_KEY:
            validationMsg = "REQUIRED_FIELD";
            break;
        case InputValidationKey._MAX_LENGTH_KEY:
            validationMsg = "MAX_LENGTH_EXCEEDED";
            break;
        case InputValidationKey._MIN_LENGTH_KEY:
            validationMsg = "MIN_LENGTH_NO_MET";
            break;
        case InputValidationKey._MIN_KEY:
            validationMsg = "MIN_ERROR";
            break;
        case InputValidationKey._MAX_KEY:
            validationMsg = "MAX_ERROR";
            break;
        case InputValidationKey._PATTERN:
            validationMsg = !stringIsNullOrEmpty(patternErrorTxt) ? patternErrorTxt : "INVALID_FORMAT";
            break;
        default:
            validationMsg = error.message
            break;
    }

    return validationMsg;
}

/// <summary>
/// Author: -
/// </summary>
export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/// <summary>
/// Author: -
/// </summary>
export function isObjectEmpty(data) {
    return data == null || Object.keys(data).length === 0 || data == undefined;
}

/// <summary>
/// Author : -
/// </summary>
export async function imagePathToFileObject(imagePath) {
    const response = await fetch(imagePath);
    const blob = await response.blob();
    const file = new File([blob], imagePath);
    return file;
}

/// <summary>
/// Author : -
/// </summary>
export function formatNumber(data, decimalPlaces) {
    return data.toLocaleString(undefined, { minimumFractionDigits: decimalPlaces })
}

/// <summary>
/// Author : -
/// </summary>
export function arrayGroupByKey(array, key) {
    return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
            currentValue
        );

        return result;
    }, {});
};

/// <summary>
/// Author: -
/// </summary>
export function numberWithCurrencyFormat(value, decimal = 2) {
    let isNegativeVal = false;
    const CURRENCY_DECIMAL = 2;

    if (value < 0) {
        value *= -1;
        isNegativeVal = true;
    }

    var convertedNumber = parseFloat((Math.round((value + Number.EPSILON) * Math.pow(10, decimal))) / Math.pow(10, decimal)).toFixed(decimal).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,');

    if (decimal > CURRENCY_DECIMAL) {
        let afterDecimalNumber = convertedNumber.split('.');
        let decimalDiff = decimal - CURRENCY_DECIMAL;
        if (afterDecimalNumber.length == 2 && convertedNumber.lastIndexOf('0') == (convertedNumber.length - 1)) {
            let processNumber = afterDecimalNumber[1];
            for (let i = processNumber.length - 1; (i > 0 && decimalDiff > 0); i--) {
                if (processNumber.charAt(i) == '0') {
                    processNumber = processNumber.substr(0, i) + processNumber.substr(i + 1);
                }
                else {
                    break;
                }
                decimalDiff--;
            }
            convertedNumber = afterDecimalNumber[0] + "." + processNumber;
        }
    }

    if (isNegativeVal) {
        convertedNumber = '-' + convertedNumber;
    }

    return convertedNumber
}

/// <summary>
/// Author : -
/// </summary>
export function ignoreDecimal(e) {
    const _KEYCODE_DECIMAL = 190;
    const _KEYCODE_E = 69;
    let keyCode = e.keyCode;

    if (keyCode == _KEYCODE_DECIMAL || keyCode == _KEYCODE_E) {
        e.preventDefault();
    }
}

/// <summary>
/// Author : -
/// </summary>
export function validatePhoneNumber(phone, iso) {
    let validateResult = false;

    try {
        const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
        let parsedPhone = phoneUtil.parse(phone, iso);
        validateResult = phoneUtil.isValidNumberForRegion(parsedPhone, iso) && phoneUtil.getNumberType(parsedPhone) == PhoneNumberType.MOBILE;
    }
    catch (error) {
        validateResult = false;
    }

    return validateResult;
}

/// <summary>
/// Author: -
/// </summary>
export function removeUndefinedFromObject(obj) {
    for (let key in obj) {
        if (obj[key] === undefined) {
            delete obj[key]
        }
    }

    return obj;
}

/// <summary>
/// Author: -
/// - - Able to handle min negative value
/// </summary>
export function rangeNumberFormat(value, minValue = 0, maxValue = 99999999) {
    var formattedValue = minValue;

    if (isNumeric(value)) {
        value = value.replace(/[^\d.-]/g, '');

        if (!stringIsNullOrEmpty(value)) {
            if (value['length'] != minValue.toString()['length'] && value.substring(0, minValue.toString()['length']) == minValue.toString()) {
                value = value.substring(minValue.toString()['length']);
            }

            formattedValue = Math.min(maxValue, Math.max(minValue, parseFloat(value)));
        }
    }

    return formattedValue;
}

/// <summary>
/// Author: -
/// </summary>
export function isNumeric(value) {
    return !isNaN(value - parseFloat(value));
}

/// <summary>
/// Author: -
/// </summary>
export function useQuery(location) {
    return new URLSearchParams(location.search);
}

/// <summary>
/// Author: -
/// </summary>
export function compareInAscending(a, b, colName) {
    // Use toUpperCase() to ignore character casing
    const nameA = a[colName].toUpperCase();
    const nameB = b[colName].toUpperCase();

    let comparison = 0;

    if (nameA > nameB) {
        comparison = 1;
    } else if (nameA < nameB) {
        comparison = -1;
    }
    return comparison;
}

/// <summary>
/// Author: -
/// </summary>
export function createStorageListener(store) {
    return () => {
        _LOCAL_STORAGE_DISPATCH.map(i => {
            let storageItem = localStorage.getItem(i.key);
            if (storageItem != null) {
                store.dispatch({
                    type: i.dispatchKey,
                    data: JSON.parse(storageItem)
                });

                localStorage.removeItem(i.key);
            }
        })
    }
}

/// <summary>
/// Author: -
/// </summary>
export function decimalValueInput(value) {
    return value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
}

/// <summary>
/// Author: -
/// </summary>
export function getAppName() {
    return process.env.REACT_APP_NAME.toUpperCase() != getHostname().toUpperCase() ? toTitleCase(getHostname()) : process.env.REACT_APP_NAME;
}

/// <summary>
/// Author: -
/// </summary>
export function getHostname() {
    let hostName = window.location.hostname.split('.');

    return hostName.length <= 2 ? hostName[0] : hostName[1];
}

/// <summary>
/// Author: -
/// </summary>
export function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}