import { Drawer, Navbar } from "@mantine/core";
import Brand from "./_brand";
import Links from "./_links";
import User from "./_user";

/// <summary>
/// Author: CK
/// </summary>
export default function NavBar({ hidden = true }) {
    return (
        <Navbar width={{ base: 230 }} p="xs" style={{ backgroundColor: "#2e3b61" }} hidden={hidden} hiddenBreakpoint="sm">
            <Navbar.Section>
                <Brand />
            </Navbar.Section>
            <Navbar.Section grow>
                <Links />
            </Navbar.Section>
            <Navbar.Section>
                <User />
            </Navbar.Section>
        </Navbar>
    );
}

export function NavBarDrawer({ opened, handlers }) {
    return (
        <Drawer
            opened={opened}
            onClose={handlers.toggle}
            size={230}
            sx={(theme) => ({
                ".mantine-Drawer-header": { display: "none" },
                "--mantine-header-height": "0px",
                ".mantine-Navbar-root": { borderRight: 0 },
            })}
        >
            <NavBar hidden={false} />
        </Drawer>
    );
}
