import React, { useState } from 'react';
import { Modal, TextInput, Button, Group } from '@mantine/core';

function ConfirmationDialog({ 
    opened, 
    onClose,
    onConfirm,
    title = "Confirmation Dialog",
    inputLabel = "Please enter your value",
    confirmBtnTxt = "Confirm",
    confirmBtnColor = "green"
}) {
    const [inputValue, setInputValue] = useState('');

    const handleConfirm = () => {
        onConfirm(inputValue);
        setInputValue("");
        onClose();
    };

    return (
        <Modal
            opened={opened}
            onClose={onClose}
            title={title}
        >
            <TextInput
                label={inputLabel}
                placeholder={inputLabel}
                value={inputValue}
                onChange={(event) => setInputValue(event.currentTarget.value)}
            />
            <Group position="right" mt="md">
                <Button variant="outline" onClick={onClose}>Cancel</Button>
                <Button bg={confirmBtnColor} onClick={handleConfirm}>{confirmBtnTxt}</Button>
            </Group>
        </Modal>
    );
}

export default ConfirmationDialog;