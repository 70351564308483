import { ActionIcon, Box, Card, Group, Input, InputWrapperProps, ScrollArea, Stack, Text, createStyles } from "@mantine/core";
import { DropzoneProps, FileWithPath, Dropzone as MantineDropzone } from "@mantine/dropzone";
import { UseListStateHandlers, useDidUpdate, useListState } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { IconX } from "@tabler/icons";
import { ReactNode, useEffect, useRef } from "react";

const useStyles = createStyles((theme, params, getRef) => ({
    root: {
        cursor: "initial",
        backgroundColor: "#f8f9fa",
    },
    groupContainer: {
        height: 150,
    },
    imagePreview: {
        borderRadius: theme.radius.sm,
        boxShadow: theme.shadows.md,
    },
    dropzoneError: {
        borderColor: theme.colors.red[6],
        backgroundColor: theme.colors.red[0],
    },
}));

/**
 * @param {Omit<DropzoneProps, 'onDrop' | 'activateOnClick' | 'openRef' | 'children'> & {note?: string, inputWrapperProps?: Omit<InputWrapperProps, 'error'>, error?: ReactNode}} props
 */
export default function Dropzone(props) {
    const { className, note, onChange, inputWrapperProps, error, ...restProps } = props;
    const { classes, cx } = useStyles();

    /** @type {[FileWithPath[] , UseListStateHandlers<FileWithPath]>} */
    const [files, filesHandlers] = useListState([]);
    /** @type {[string[] , UseListStateHandlers<string]>} */
    const [imageUrls, imageUrlsHandlers] = useListState([]);
    const imageUrlsRef = useRef();
    const openRef = useRef();

    /** @param {FileWithPath[]} values */
    function onDrop(values) {
        let fileLength = files.length;
        let totalFileSize = files.reduce((size, file) => {
            return size + file.size;
        }, 0);
        values.forEach((value) => {
            let isDuplicate = files.some((file) => file.name === value.name);
            if (isDuplicate) {
                showNotification({
                    message: `Duplicate file '${value.name}'`,
                });
            } else {
                let append = true;
                if (restProps.maxFiles && fileLength >= restProps.maxFiles) {
                    append = false;
                    showNotification({
                        message: `Maximum ${restProps.maxFiles} file`,
                    });
                }
                if (restProps.maxSize && totalFileSize + value.size > restProps.maxSize) {
                    append = false;
                    showNotification({
                        message: `'${value.name}' exceeds maximum allowed size`,
                    });
                }
                if (append) {
                    fileLength += 1;
                    totalFileSize += value.size;
                    filesHandlers.append(value);
                    imageUrlsHandlers.append(URL.createObjectURL(value));
                }
            }
        });
    }

    function handleRemoveFile(index) {
        filesHandlers.remove(index);
        URL.revokeObjectURL(imageUrls[index]);
        imageUrlsHandlers.remove(index);
    }

    useDidUpdate(() => {
        typeof onChange === "function" && onChange(files);
    }, [files]);

    useEffect(
        () => () => {
            imageUrlsRef.current = imageUrls;
        },
        [imageUrls]
    );

    useEffect(() => {
        return () => {
            filesHandlers.setState([]);
            imageUrlsRef.current?.forEach((imageUrl) => URL.revokeObjectURL(imageUrl));
            imageUrlsHandlers.setState([]);
        };
    }, []);

    return (
        <Input.Wrapper {...inputWrapperProps} error={error}>
            <MantineDropzone
                {...restProps}
                className={cx(classes.root, className, { [classes.dropzoneError]: error, ["dropzone-invalid"]: error })}
                aria-invalid={error ? true : false}
                onDrop={onDrop}
                activateOnClick={false}
                openRef={openRef}
            >
                <Stack className={classes.groupContainer} align="center" justify="center">
                    <div>
                        <Text size="xl" inline align="center" sx={{ display: "inline-block" }}>
                            Drop images here or{" "}
                            <Text
                                color="blue"
                                sx={{
                                    cursor: "pointer",
                                    pointerEvents: "auto",
                                    textAlign: "center",
                                    display: "inline-block",
                                    ":hover": { textDecoration: "underline" },
                                }}
                                inline
                                onClick={() => openRef.current()}
                            >
                                browse
                            </Text>
                        </Text>
                        {note && (
                            <Text size="sm" color="dimmed" inline align="center" mt={7}>
                                {note}
                            </Text>
                        )}
                    </div>
                    {imageUrls.length > 0 && (
                        <Card withBorder sx={{ width: "100%" }} pr={0}>
                            <ScrollArea sx={{ height: "100%", pointerEvents: "auto" }} scrollbarSize={7.5}>
                                <Stack pr="md" spacing={5}>
                                    {imageUrls.map((imageUrl, i) => (
                                        <Group key={imageUrl} position="apart" noWrap>
                                            <Group spacing="xs" noWrap>
                                                <Box sx={{ flex: "0 0 auto" }}>
                                                    <img className={classes.imagePreview} width={50} height={50} objectFit="cover" src={imageUrl} placeholder="blur" blurDataURL={imageUrl} alt="" />
                                                </Box>
                                                <Text color="dimmed" size="sm" lineClamp={1}>
                                                    {files[i].name}
                                                </Text>
                                            </Group>
                                            <ActionIcon radius="xl" variant="filled" color="dark" size="xs" onClick={() => handleRemoveFile(i)}>
                                                <IconX size={12} />
                                            </ActionIcon>
                                        </Group>
                                    ))}
                                </Stack>
                            </ScrollArea>
                        </Card>
                    )}
                </Stack>
            </MantineDropzone>
        </Input.Wrapper>
    );
}
