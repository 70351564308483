import { Button, Group, LoadingOverlay, Loader, Modal, ModalProps, FileButton, Stack, Box, TextInput, createStyles, Avatar, Select } from "@mantine/core";
import { MIME_TYPES } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { useEffect, useRef, useState } from "react";
import { ApiRoutes, StaffType, PasswordFormType, RoleType } from "../../constants";
import ApiEngine, { ResponseData } from "../../utils/ApiEngine";
import { getFormData, showAlert, constructQueryString, stringIsNullOrEmpty } from "../../utils/Utils";
import { Dropzone } from "../elements/Dropzone";
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedState, useDidUpdate, useDisclosure } from "@mantine/hooks";
import { IconUser, IconCategory, IconArrowUpCircle, IconSubtask } from "@tabler/icons";

const useStyles = createStyles((theme, params, getRef) => ({
    avatar: {
        objectFit: "contain",
        backgroundColor: "white",
        border: "2px solid",
        borderColor: theme.colorScheme === "dark" ? "#FFFFFF" : "#000000",
        borderRadius: theme.radius.md,
        width: 100,
        height: 100,
    },
    avatarContainer: {
        position: "relative",
        cursor: "pointer",
        width: "fit-content",
        margin: "auto",

        ".icon.icon-tabler-arrow-up-circle": {
            background: "white",
            position: "absolute",
            bottom: -5,
            left: 0,
            right: -85,
            margin: "auto",
            borderRadius: theme.radius.xl,
            height: 32,
            width: 32,
        },
    },
}));

/**
 * @param {ModalProps & {staff?: StaffType, successCallback?: function}} props
 */
export default function CreateUpdateModal(props) {
    const { opened, centered = true, staff: staffProp, successCallback, ...restProps } = props;

    const { classes } = useStyles();
    /** @type {[StaffType|undefined, Dispatch<SetStateAction<StaffType|undefined>>]} */
    const [staff, setStaff] = useState();
    const [loading, setLoading] = useState(false);
    const [searchBranch, setSearchBranch] = useDebouncedState("", 300);
    const [searchBranchLoading, setSearchBranchLoading] = useState(false);
    const [staffCategories, setStaffCategories] = useState([]);
    const [originalStaffCategories, setOriginalStaffCategories] = useState([]);
    const [staffCategoriesLoading, setStaffCategoriesLoading] = useState(false);
    const [staffSubCategoriesLoading, setStaffSubCategoriesLoading] = useState(false);
    const [branchOpts, setBranchOpts] = useState([]);
    const [file, setFile] = useState();
    const [imageUrl, setImageUrl] = useState();
    const [staffSubCategories, setStaffSubCategories] = useState([]);
    var _userData = useSelector((state) => state.authState.userData);

    const form = useForm({
        initialValues: {
            branchId: "",
            category: "",
            name: "",
            image: undefined,
            subCategory: ""
        },
        validate: {
            branchId: (value) => (value === "" ? true : undefined),
            category: (value) => (value === "" ? true : undefined),
            name: (value) => (value === "" ? true : undefined),
            // image: (value) => ((!value || !(value instanceof File)) && !staff ? true : undefined),
        },
    });

    /** @param {Partial<StaffType>} values */
    async function onSubmit(values) {
        try {
            setLoading(true);

            if (file) {
                values.image = file;
            }

            let requestFn;
            let url = ApiRoutes.STAFF;
            if (staff) {
                requestFn = ApiEngine.put;
                url += `/${values.id}`;
                values['branchId'] = staff.branchId;
            } else {
                requestFn = ApiEngine.post;

                if (!RoleType.SHOP) {
                    throw "Only shop is allowed to create staff.";
                }
            }

            console.log("Staff params", values);
            let body = getFormData(values);

            /** @type {ResponseData} */
            const response = await requestFn(url, body);

            if (!response.success) {
                throw response.message;
            }
            showAlert("success", response.message);
            typeof successCallback === "function" && successCallback(values);
            props.onClose();
        } catch (error) {
            console.log("Error", error);
            showAlert("fail", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        file ? setImageUrl(URL.createObjectURL(file)) : setImageUrl();
    }, [file]);

    useEffect(() => {
        return () => imageUrl && URL.revokeObjectURL(imageUrl);
    }, [imageUrl]);

    useEffect(() => {
        (async () => {
            try {
                if (!stringIsNullOrEmpty(form.values?.category) && originalStaffCategories.findIndex(c => c.id == form.values.category && c.hasSub) > -1) {
                    setStaffSubCategoriesLoading(true);
                    var response = await ApiEngine.get(ApiRoutes.BRANCH_SUB_CATEGORY + form.values.category);

                    if (!response.success) {
                        throw response.message;
                    }

                    let data = response.data ?? [];
                    setStaffSubCategories(data.map(({ id, name }) => ({ label: name, value: id })));
                }
                else {
                    setStaffSubCategories([]);
                }
            }
            catch (err) {
                showAlert("fail", err);
            }
            finally {
                setStaffSubCategoriesLoading(false);
            }
        })();
    }, [form.values.category, originalStaffCategories]);

    useEffect(() => {
        if (opened) {
            setFile();
            setImageUrl();
            setStaff();
            form.setFieldValue('image', null);
            form.reset();
            getStaffCategories();

            if (staffProp) {
                getStaffDetails(staffProp.id);
            }

            searchBranchOpts("");
        }
    }, [opened]);

    async function getStaffDetails(value) {
        try {
            const response = await ApiEngine.get(`${ApiRoutes.STAFF}/${value}`);

            if (!response.success) {
                throw response.message;
            }

            let data = response.data;
            setStaff(data);
            form.setValues(data);
        } catch (error) {
            showAlert("fail", error);
        } finally {
            setSearchBranchLoading(false);
        }
    }

    async function searchBranchOpts(value) {
        try {
            setSearchBranchLoading(true);

            let url = constructQueryString(ApiRoutes.BRANCH, { shopId: _userData.roleId > RoleType.ADMIN ? _userData.id : '', name: !stringIsNullOrEmpty(value) ? value : "" });
            /** @type {ResponseData} */
            const response = await ApiEngine.get(url);

            if (!response.success) {
                throw response.message;
            }

            let data = response.data ?? [];

            setBranchOpts(data.map(({ id, name }) => ({ label: name, value: id })));
        } catch (error) {
            showAlert("fail", error);
        } finally {
            setSearchBranchLoading(false);
        }
    }

    async function getStaffCategories() {
        try {
            setStaffCategoriesLoading(true);

            let url = constructQueryString(ApiRoutes.BRANCH_CATEGORY);
            /** @type {ResponseData} */
            const response = await ApiEngine.get(url);

            if (!response.success) {
                throw response.message;
            }

            let data = response.data ?? [];

            setStaffCategories(data.map(({ id, name}) => ({ label: name, value: id })));
            setOriginalStaffCategories(data);
        } catch (error) {
            showAlert("fail", error);
        } finally {
            setStaffCategoriesLoading(false);
        }
    }

    // useDidUpdate(() => {
    //     // if (!stringIsNullOrEmpty(searchBranch)) {
    //     //     searchBranchOpts(searchBranch);
    //     // } else {
    //     //     // setBranchOpts([]);
    //     // }
    // }, [searchBranch]);

    return (
        <Modal opened={opened} centered={centered} title={`${staff ? "Edit" : "Add"} Staff`} {...restProps}>
            <form onSubmit={form.onSubmit(onSubmit)}>
                <Stack>
                    <FileButton onChange={setFile} accept="image/png,image/jpeg">
                        {(fileInputProps) => (
                            <Box {...fileInputProps} className={classes.avatarContainer}>
                                <Avatar className={classes.avatar} src={imageUrl ? imageUrl : !stringIsNullOrEmpty(staff?.image) ? staff.image : null} />
                                <IconArrowUpCircle />
                            </Box>
                        )}
                    </FileButton>
                    <Select
                        {...form.getInputProps("branchId")}
                        label="Branch"
                        placeholder="Branch"
                        icon={<IconUser size={18} />}
                        data={branchOpts}
                        searchable
                        onSearchChange={(value) => {
                            if (!stringIsNullOrEmpty(form.values.branchId)) {
                                const opt = branchOpts.find(({ value }) => value === form.values.branchId);
                                opt?.label !== value && setSearchBranch(value);
                            } else {
                                if (branchOpts.length > 0) {
                                    setSearchBranch(branchOpts[0].value);
                                    form.setFieldValue("branchId", branchOpts[0].value);
                                }
                                else {
                                    setSearchBranch(value);
                                    form.setFieldValue("branchId", "");
                                }
                            }
                        }}
                        nothingFoundMessage="Nothing found..."
                        rightSection={searchBranchLoading && <Loader size="xs" />}
                    />
                    <Select
                        {...form.getInputProps("category")}
                        label="Category"
                        placeholder="Category"
                        icon={<IconCategory size={18} />}
                        data={staffCategories}
                        rightSection={staffCategoriesLoading && <Loader size="xs" />}
                        withAsterisk
                    />
                    {
                        staffSubCategories.length > 0 &&
                        <Select
                            {...form.getInputProps("subCategory")}
                            label="Sub-Category"
                            placeholder="Sub-Category"
                            icon={<IconSubtask size={18} />}
                            data={staffSubCategories}
                            rightSection={staffSubCategoriesLoading && <Loader size="xs" />}
                            withAsterisk
                        />
                    }
                    <TextInput {...form.getInputProps("name")} label="Name" placeholder="Name" withAsterisk />
                    <Group position="right">
                        <Button variant="default" onClick={props.onClose}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="filled">
                            Save
                        </Button>
                    </Group>
                </Stack>
            </form>
            <LoadingOverlay visible={loading} />
        </Modal>
    );
}
