import { Button, Group, LoadingOverlay, Loader, Modal, ModalProps, NumberInput, Stack, Text, TextInput, createStyles, Switch, Select } from "@mantine/core";
import { MIME_TYPES } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { useEffect, useRef, useState } from "react";
import { ApiRoutes, GiftType, PasswordFormType, RoleType } from "../../constants";
import ApiEngine, { ResponseData } from "../../utils/ApiEngine";
import { getFormData, showAlert, constructQueryString, stringIsNullOrEmpty } from "../../utils/Utils";
import { Dropzone } from "../elements/Dropzone";
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedState, useDidUpdate, useDisclosure } from "@mantine/hooks";
import { IconUser } from "@tabler/icons";

const useStyles = createStyles((theme, params, getRef) => ({
    avatar: {
        objectFit: "contain",
        backgroundColor: "white",
        border: "2px solid",
        borderColor: theme.colorScheme === "dark" ? "#FFFFFF" : "#000000",
        borderRadius: theme.radius.md,
        width: 100,
        height: 100,
    },
    avatarContainer: {
        position: "relative",
        cursor: "pointer",
        width: "fit-content",
        margin: "auto",

        ".icon.icon-tabler-arrow-up-circle": {
            background: "white",
            position: "absolute",
            bottom: -5,
            left: 0,
            right: -85,
            margin: "auto",
            borderRadius: theme.radius.xl,
            height: 32,
            width: 32,
        },
    },
}));

/**
 * @param {ModalProps & {gift?: GiftType, successCallback?: function}} props
 */
export default function CreateUpdateModal(props) {
    const { opened, centered = true, gift: giftProp, successCallback, ...restProps } = props;

    const { classes } = useStyles();
    /** @type {[GiftType|undefined, Dispatch<SetStateAction<GiftType|undefined>>]} */
    const [gift, setGift] = useState();
    const [loading, setLoading] = useState(false);
    const [searchBranch, setSearchBranch] = useDebouncedState("", 300);
    const [searchBranchLoading, setSearchBranchLoading] = useState(false);
    const [branchOpts, setBranchOpts] = useState([]);
    var _userData = useSelector((state) => state.authState.userData);

    const form = useForm({
        initialValues: {
            branchId: "",
            name: "",
            amount: undefined,
            virtualGiftImage: undefined,
            presetMessage: false,
            customMessage: true,
            uploadImage: false,
            staffTipping: false,
            displayTime: undefined
        },
        validate: {
            branchId: (value) => (value === "" ? true : undefined),
            name: (value) => (value === "" ? true : undefined),
            amount: (value) => (!value ? true : undefined),
            // image: (value) => ((!value || !(value instanceof File)) && !gift ? true : undefined),
        },
    });

    const imageInputProps = form.getInputProps("virtualGiftImage");

    /** @param {Partial<GiftType>} values */
    async function onSubmit(values) {
        try {
            setLoading(true);

            let requestFn;
            let url = ApiRoutes.GIFT;
            if (gift) {
                requestFn = ApiEngine.put;
                url += `/${values.id}`;
                values['branchId'] = gift.branchId;
            } else {
                requestFn = ApiEngine.post;

                if (!RoleType.SHOP) {
                    throw "Only shop is allowed to create gift.";
                }
            }

            console.log("Gift params", values);
            let body = getFormData(values);

            /** @type {ResponseData} */
            const response = await requestFn(url, body);

            if (!response.success) {
                throw response.message;
            }
            showAlert("success", response.message);
            typeof successCallback === "function" && successCallback(values);
            props.onClose();
        } catch (error) {
            console.log("Error", error);
            showAlert("fail", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (opened) {
            form.reset();
            setGift(giftProp);

            if (giftProp) {
                form.setValues(giftProp);
                form.setFieldValue('image', null);
            }

            searchBranchOpts("");
        }
    }, [opened]);

    async function searchBranchOpts(value) {
        try {
            setSearchBranchLoading(true);

            let url = constructQueryString(ApiRoutes.BRANCH, { shopId: _userData.roleId > RoleType.ADMIN ? _userData.id : '', name: !stringIsNullOrEmpty(value) ? value : ""  });
            /** @type {ResponseData} */
            const response = await ApiEngine.get(url);

            if (!response.success) {
                throw response.message;
            }

            let data = response.data ?? [];

            setBranchOpts(data.map(({ id, name }) => ({ label: name, value: id })));
        } catch (error) {
            showAlert("fail", error);
        } finally {
            setSearchBranchLoading(false);
        }
    }

    // useDidUpdate(() => {
    //     // if (!stringIsNullOrEmpty(searchBranch)) {
    //     //     searchBranchOpts(searchBranch);
    //     // } else {
    //     //     // setBranchOpts([]);
    //     // }
    // }, [searchBranch]);
  
    return (
        <Modal opened={opened} centered={centered} title={`${gift ? "Edit" : "Add"} Gift`} {...restProps}>
            <form onSubmit={form.onSubmit(onSubmit)}>
                <Stack>
                    <Select
                        {...form.getInputProps("branchId")}
                        label="Branch"
                        placeholder="Branch"
                        icon={<IconUser size={18} />}
                        data={branchOpts}
                        searchable
                        onSearchChange={(value) => {
                            if (!stringIsNullOrEmpty(form.values.branchId)) {
                                const opt = branchOpts.find(({ value }) => value === form.values.branchId);
                                opt?.label !== value && setSearchBranch(value);
                            } else {
                                if (branchOpts.length > 0) {
                                    setSearchBranch(branchOpts[0].value);
                                    form.setFieldValue("branchId", branchOpts[0].value);
                                }
                                else {
                                    setSearchBranch(value);
                                    form.setFieldValue("branchId", "");
                                }
                            }
                        }}
                        nothingFoundMessage="Nothing found..."
                        rightSection={searchBranchLoading && <Loader size="xs" />}
                    />
                    <TextInput {...form.getInputProps("name")} label="Name" placeholder="Name" withAsterisk />
                    <NumberInput {...form.getInputProps("amount")} label="Amount" placeholder="Amount" withAsterisk hideControls min={0} precision={2} />
                    <NumberInput {...form.getInputProps("displayTime")} label="Display Time(s)" placeholder="Display Time(s)" hideControls min={0} precision={0} />
                    <Switch {...form.getInputProps("presetMessage")} checked={form.values.presetMessage} label="Preset Message" />
                    <Switch {...form.getInputProps("customMessage")} checked={form.values.customMessage} label="Custom Message" />
                    <Switch {...form.getInputProps("uploadImage")} checked={form.values.uploadImage} label="Customer Upload Image" />
                    <Switch {...form.getInputProps("staffTipping")} checked={form.values.staffTipping} label="Staff Tipping" />
                    <TextInput {...form.getInputProps("displayRemark")} label="Display Remark" placeholder="Display Remark" />
                    <Dropzone
                        note="Up to 5MB"
                        accept={[MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.svg]}
                        maxFiles={1}
                        maxSize={5 * 1024 ** 2}
                        onChange={(files) => (files?.length > 0 ? imageInputProps.onChange(files[0]) : imageInputProps.onChange())}
                        error={imageInputProps.error}
                    />
                    <Group position="right">
                        <Button variant="default" onClick={props.onClose}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="filled">
                            Save
                        </Button>
                    </Group>
                </Stack>
            </form>
            <LoadingOverlay visible={loading} />
        </Modal>
    );
}
