import { LoadingOverlay, LoadingOverlayProps, MenuItemProps, PaginationProps, ScrollArea, Stack, Table, TableProps, Text } from "@mantine/core";
import { useDidUpdate, useElementSize, useListState, useToggle } from "@mantine/hooks";
import { IconBoxOff } from "@tabler/icons";
import { ColumnDef, ColumnFiltersState, PaginationState, RowData, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import { ComponentPropsWithoutRef, Dispatch, FC, MouseEventHandler, RefAttributes, SetStateAction, forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import ApiEngine, { ResponseData } from "../../../utils/ApiEngine";
import { constructQueryString, showAlert } from "../../../utils/Utils";
import useStyles from "./DataGrid.styles";
import Pagination, { DefaultPaginationState } from "./Pagination";
import RowContextMenu from "./RowContextMenu";

/**
 * @typedef {Object} CustomPaginationState
 * @property {number} current - pageIndex
 * @property {number} pageSize - pageSize
 * @param {PaginationState} values
 * @returns {CustomPaginationState|values}
 */
function parseCustomPaginationState(values) {
    if (values && Object.keys(values).length > 0) {
        return {
            current: values.pageIndex + 1,
            pageSize: values.pageSize,
        };
    }
    return values;
}

/**
 * @param {Record<string, any>} values
 * @returns {ColumnFiltersState|[]}
 */
function parseColumnsFiltersState(values) {
    let result = [];
    if (values && Object.keys(values).length > 0) {
        result = Object.entries(values).map(([key, data]) => ({ id: key, value: data }));
    }
    return result;
}

/**
 * @param {ColumnFiltersState} values
 * @returns {Record<string, any>|undefined}
 */
function parseRecord(values) {
    let result;
    if (values && values.length > 0) {
        result = values.reduce((obj, columnFilter) => {
            if (columnFilter.id && columnFilter.value) {
                obj[columnFilter.id] = columnFilter.value;
            }
            return obj;
        }, {});
    }
    return result;
}

/**
 * @typedef {Object} ReactTableProps
 * @property {ColumnDef<unknown>[]} columns
 * @property {Array<Object>} [data] - provide data or api
 * @property {string} [api] - if api is provided, the data prop will be ignore
 * @property {PaginationState} [defaultPaginationState]
 * @property {Record<string, any>} [filterValues]
 * @property {function(RowData)} [onRowClick]
 * @typedef {Object} RowContextMenuOption
 * @property {function(RowData): (MenuItemProps & {label: string} & ComponentPropsWithoutRef<"button">)[]} items
 * @typedef {ReactTableProps & TableProps & { paginationProps?: PaginationProps, loadingOverlayProps?: LoadingOverlayProps, rowContextMenuOption?: RowContextMenuOption }} DataGridProps
 * @type {FC<DataGridProps & RefAttributes>}
 */
const DataGrid = forwardRef((props, ref) => {
    const { columns = [], data: dataProp, api, defaultPaginationState = DefaultPaginationState, filterValues, paginationProps, loadingOverlayProps, rowContextMenuOption, onRowClick, ...rest } = props;
    const headerElSize = useElementSize();
    const { classes, cx } = useStyles({ headerHeight: headerElSize.height });
    const [data, dataHandlers] = useListState([]);
    const isServerSide = useMemo(() => Boolean(api && typeof api === "string" && api !== ""), [api]);
    const [isLoading, setIsLoading] = useState(false);
    const [pageCount, setPageCount] = useState();
    const [reload, toggleReload] = useToggle();

    /**
     * @typedef {{top: number, left: number, rowData: RowData}|undefined} ContextMenuInfo
     * @type {[ContextMenuInfo, Dispatch<SetStateAction<ContextMenuInfo>>]}
     */
    const [rowContextMenuInfo, setRowContextMenuInfo] = useState();

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        initialState: {
            pagination: defaultPaginationState,
            columnFilters: parseColumnsFiltersState(filterValues),
        },
        pageCount: isServerSide ? pageCount : undefined,
        manualPagination: isServerSide,
        getPaginationRowModel: getPaginationRowModel(),
        manualFiltering: isServerSide,
        getFilteredRowModel: getFilteredRowModel(),
    });

    const { pagination, columnFilters, sorting } = table.getState();

    /** @param {Record<string, any>} filterObj */
    async function fetchData(filterObj) {
        try {
            setIsLoading(true);
            let url = constructQueryString(api, filterObj);
            /** @type {ResponseData} */
            const response = await ApiEngine.get(url);

            if (!response.success) {
                throw response.message;
            }

            let data = response.data ?? [];
            let totalCount = response.totalCount ?? data?.length ?? 0;
            dataHandlers.setState(data);
            setPageCount(Math.ceil(totalCount / pagination.pageSize));
        } catch (error) {
            showAlert("fail", error);
        } finally {
            setIsLoading(false);
        }
    }

    useDidUpdate(() => {
        table.setState((prev) => ({
            ...prev,
            pagination: { pageIndex: 0, pageSize: prev.pagination.pageSize },
            columnFilters: parseColumnsFiltersState(filterValues),
        }));
    }, [filterValues]);

    useEffect(() => {
        if (isServerSide) {
            fetchData({ ...(!paginationProps?.hidden ? parseCustomPaginationState(pagination) : {}), ...parseRecord(columnFilters) });
        }
    }, [isServerSide, pagination, columnFilters, sorting, reload, paginationProps?.hidden]);

    useEffect(() => {
        if (dataProp && Array.isArray(dataProp) && !isServerSide) {
            dataHandlers.setState(dataProp);
        }
    }, [isServerSide, dataProp]);

    useImperativeHandle(
        ref,
        () => ({
            reload: () => toggleReload(),
        }),
        []
    );

    return (
        <div className={cx(classes.tableContainer, "tableContainer")}>
            <div className="tableWrapper">
                <ScrollArea onScrollPositionChange={() => setRowContextMenuInfo()}>
                    <Table {...rest} className={`${rowContextMenuOption || (onRowClick && typeof onRowClick === "function") ? "row-clickable" : ""}`}>
                        <thead ref={headerElSize.ref}>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <th key={header.id}>{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.length > 0 ? (
                                table.getRowModel().rows.map((row) => {
                                    /** @type {MouseEventHandler<HTMLTableRowElement> | undefined} */
                                    let onClick;

                                    if (rowContextMenuOption) {
                                        onClick = (e) => {
                                            setRowContextMenuInfo({
                                                top: e.clientY,
                                                left: e.clientX,
                                                rowData: row.original,
                                            });
                                            onRowClick?.(row.original);
                                        };
                                    } else if (onRowClick && typeof onRowClick === "function") {
                                        onClick = () => {
                                            onRowClick(row.original);
                                        };
                                    }

                                    return (
                                        <tr key={row.id} onClick={onClick} className={cx({ active: rowContextMenuInfo?.rowData === row.original })}>
                                            {row.getVisibleCells().map((cell) => (
                                                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                                            ))}
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={table.getVisibleLeafColumns().length}>
                                        <Stack className={classes.noDataRow} align="center" spacing="xs">
                                            <IconBoxOff size={64} />
                                            <Text>No Data</Text>
                                        </Stack>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </ScrollArea>
                <LoadingOverlay visible={isLoading} {...loadingOverlayProps} />
            </div>
            <Pagination {...paginationProps} table={table} />
            {rowContextMenuOption && rowContextMenuInfo && (
                <RowContextMenu
                    top={rowContextMenuInfo.top}
                    left={rowContextMenuInfo.left}
                    rowData={rowContextMenuInfo.rowData}
                    onClose={() => {
                        setRowContextMenuInfo();
                    }}
                    items={rowContextMenuOption.items}
                />
            )}
        </div>
    );
});

DataGrid.displayName = "DataGrid";

export default DataGrid;
