import { ActionIcon, Button, Divider, Group, Stack, Text, TextInput, Switch } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import { IconEdit, IconGift, IconPlus, IconRotate, IconSearch, IconTrashX, IconCircleCheck } from "@tabler/icons";
import { ColumnHelper, createColumnHelper } from "@tanstack/react-table";
import { Dispatch, SetStateAction, useMemo, useRef, useState } from "react";
import { DataGrid } from "../../components/elements/DataGrid";
import CreateUpdateModal from "../../components/gifts/CreateUpdateModal";
import Layout from "../../components/layout";
import { APP_NAME, ApiRoutes, GiftType } from "../../constants";
import ApiEngine, { ResponseData } from "../../utils/ApiEngine";
import { showAlert, stringIsNullOrEmpty } from "../../utils/Utils";

/** @type {ColumnHelper<GiftType>} */
const columnHelper = createColumnHelper();

export default function Gifts() {
    const [filterValues, setFilterValues] = useState();
    const [modalOpened, modalHandlers] = useDisclosure(false);
    /** @type {[GiftType|undefined, Dispatch<SetStateAction<GiftType|undefined>>]} */
    const [selectGift, setSelectGift] = useState();
    const dataGridRef = useRef();

    const columns = useMemo(
        () => [
            columnHelper.accessor("branchName", { header: "Branch" }),
            columnHelper.display(
                {
                    id: "images",
                    header: "Image",
                    cell: ({ row }) =>
                        row.original.virtualGiftImage ?
                            <img width={40} height={40} objectFit="cover" src={row.original.virtualGiftImage ?? ""} placeholder="blur" blurDataURL={row.original.virtualGiftImage ?? ""} alt="" />
                            :
                            <span>-</span>
                }
            ),
            columnHelper.accessor("name", { header: "Name" }),
            columnHelper.accessor((row) => row.amount.toFixed(2), { header: "Amount" }),
            columnHelper.accessor((row) => row.displayTime + "s", { header: "Display Time" }),
            columnHelper.display(
                {
                    id: "presetMessage",
                    header: "Preset Message",
                    cell: ({ row }) =>
                        row.original.presetMessage ?
                            <IconCircleCheck size={22} color="green" />
                            :
                            <span>-</span>
                }
            ),
            columnHelper.display(
                {
                    id: "customMessage",
                    header: "Custom Message",
                    cell: ({ row }) =>
                        row.original.customMessage ?
                            <IconCircleCheck size={22} color="green" />
                            :
                            <span>-</span>
                }
            ),
            columnHelper.display(
                {
                    id: "uploadImage",
                    header: "Customer Upload Image",
                    cell: ({ row }) =>
                        row.original.uploadImage ?
                            <IconCircleCheck size={22} color="green" />
                            :
                            <span>-</span>
                }
            ),
            columnHelper.display(
                {
                    id: "staffTipping",
                    header: "Staff Tipping",
                    cell: ({ row }) =>
                        row.original.staffTipping ?
                            <IconCircleCheck size={22} color="green" />
                            :
                            <span>-</span>
                }
            )
        ],
        []
    );

    const form = useForm({
        /** @typedef {{ name: string }} initialValues */
        initialValues: {
            name: "",
        },
    });

    /** @param {initialValues} values */
    function onSubmit(values) {
        setFilterValues(values);
    }

    /** @param {string} id @param {string} name */
    function deleteGift(id, name) {
        if (!stringIsNullOrEmpty(id)) {
            openConfirmModal({
                title: "Confirmation",
                centered: true,
                children: <Text color="dimmed">Are you sure you would like to delete {name}?</Text>,
                labels: { confirm: "Yes", cancel: "No" },
                onConfirm: async () => {
                    try {
                        /** @type {ResponseData} */
                        const response = await ApiEngine.delete(`${ApiRoutes.GIFT}/${id}`);

                        if (!response.success) {
                            throw response.message;
                        }

                        showAlert("success", response.message);
                        dataGridRef.current?.reload();
                    } catch (error) {
                        showAlert("fail", error);
                    }
                },
            });
        }
    }

    return (
        <>
            <Group>
                <Button leftIcon={<IconPlus size={18} />} onClick={modalHandlers.open}>
                    Add
                </Button>
                <Text size="xl">Gifts</Text>
            </Group>
            <Divider mt="xs" mb="md" />
            <Stack>
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <Group align="flex-end">
                        <Group>
                            <TextInput {...form.getInputProps("name")} label="Name" placeholder="Name" icon={<IconGift size={18} />} />
                        </Group>
                        <Group noWrap spacing="xs" mb={1}>
                            <ActionIcon
                                variant="default"
                                radius="lg"
                                size="lg"
                                onClick={() => {
                                    form.reset();
                                    setFilterValues();
                                }}
                            >
                                <IconRotate size={20} />
                            </ActionIcon>
                            <ActionIcon type="submit" variant="filled" radius="lg" size="lg" color="blue">
                                <IconSearch size={20} />
                            </ActionIcon>
                        </Group>
                    </Group>
                </form>
                <DataGrid
                    api={ApiRoutes.GIFT}
                    columns={columns}
                    filterValues={filterValues}
                    highlightOnHover
                    paginationProps={{ position: "right" }}
                    rowContextMenuOption={{
                        items: (rowData) => [
                            {
                                label: "Edit",
                                icon: <IconEdit size={18} />,
                                onClick: () => {
                                    setSelectGift(rowData);
                                    modalHandlers.open();
                                },
                            },
                            {
                                label: "Delete",
                                icon: <IconTrashX size={18} color="red" />,
                                onClick: () => deleteGift(rowData?.id, rowData?.name),
                            },
                        ],
                    }}
                    ref={dataGridRef}
                />
            </Stack>
            <CreateUpdateModal
                opened={modalOpened}
                onClose={() => {
                    modalHandlers.close();
                    setSelectGift();
                }}
                gift={selectGift}
                successCallback={() => dataGridRef.current?.reload()}
            />
        </>
    );
}

Gifts.getLayout = function getLayout(page) {
    return <Layout>{page}</Layout>;
};
