import { ActionIcon, Avatar, Badge, BadgeProps, Button, Divider, Group, Select, Stack, Text, TextInput, createStyles } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import { IconEdit, IconIdBadge, IconPlus, IconRotate, IconSearch, IconTrashX, IconUser, IconUserCheck, IconUserX } from "@tabler/icons";
import { ColumnHelper, createColumnHelper } from "@tanstack/react-table";
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { DataGrid } from "../../components/elements/DataGrid";
import Layout from "../../components/layout";
import CreateUpdateModal from "../../components/admins/CreateUpdateModal";
import { APP_NAME, ApiRoutes, RoleType, UserStatusType, UserType } from "../../constants";
import ApiEngine, { ResponseData } from "../../utils/ApiEngine";
import { showAlert, stringIsNullOrEmpty, getFormData } from "../../utils/Utils";

/**
 * @param {RoleType} value
 * @param {BadgeProps} badgeProps
 */
function renderRoleBadge(value, badgeProps) {
    let label = Object.keys(RoleType).find((key) => RoleType[key] === value) ?? "";

    return <Badge {...badgeProps}>{label}</Badge>;
}

/**
 * @param {UserStatusType} value
 * @param {BadgeProps} badgeProps
 */
function renderStatusBadge(value, badgeProps) {
    let label = "Inactive";
    let color = "orange";
    if (value === 1) {
        label = "Active";
        color = "teal";
    }

    return (
        <Badge color={color} {...badgeProps}>
            {label}
        </Badge>
    );
}

/** @type {ColumnHelper<UserType>} */
const columnHelper = createColumnHelper();

export default function Admin() {
    const [filterValues, setFilterValues] = useState();
    const [modalOpened, modalHandlers] = useDisclosure(false);
    /** @type {[UserType|undefined, Dispatch<SetStateAction<UserType|undefined>>]} */
    const [selectUser, setSelectUser] = useState();
    const dataGridRef = useRef();

    const columns = useMemo(
        () => [
            columnHelper.accessor(
                "username",
                {
                    header: "Username",
                    cell: ({ row, getValue }) => {
                        return <Group>
                            <Avatar src={row.original.profilePicture ?? null} />
                            <Text>{getValue()}</Text>
                        </Group>
                    }
                }
            ),
            columnHelper.accessor("roleId", {
                header: "Role",
                cell: ({ getValue }) => {
                    return renderRoleBadge(getValue());
                },
            }),
            columnHelper.accessor("status", {
                header: "Status",
                cell: ({ getValue }) => {
                    return renderStatusBadge(getValue());
                },
            })
        ],
        []
    );

    const form = useForm({
        /** @typedef {{ username: string, status: string }} initialValues */
        initialValues: {
            username: "",
            status: "",
        },
    });

    /** @param {initialValues} values */
    function onSubmit(values) {
        setFilterValues(values);
    }

    /** @param {string} id @param {string} username */
    function deleteUser(id, username) {
        if (!stringIsNullOrEmpty(id)) {
            openConfirmModal({
                title: "Confirmation",
                centered: true,
                children: <Text color="dimmed">Are you sure you would like to delete {username}?</Text>,
                labels: { confirm: "Yes", cancel: "No" },
                onConfirm: async () => {
                    try {
                        /** @type {ResponseData} */
                        const response = await ApiEngine.delete(`${ApiRoutes.USER}/${id}`);

                        if (!response.success) {
                            throw response.message;
                        }

                        showAlert("success", response.message);
                        dataGridRef.current?.reload();
                    } catch (error) {
                        showAlert("fail", error);
                    }
                },
            });
        }
    }

    /** @param {string} id @param {UserStatusType} status */
    async function updateUserStatus(id, status) {
        try {
            /** @type {ResponseData} */
            let body = getFormData({ isActive: UserStatusType.ACTIVE == status });
            const response = await ApiEngine.put(`${ApiRoutes.ADMIN}/${id}`, body);

            if (!response.success) {
                throw response.message;
            }

            showAlert("success", response.message);
            dataGridRef.current?.reload();
        } catch (error) {
            showAlert("fail", error);
        }
    }

    return (
        <>
            <Group>
                <Button leftIcon={<IconPlus size={18} />} onClick={modalHandlers.open}>
                    Add
                </Button>
                <Text size="xl">Admins</Text>
            </Group>
            <Divider mt="xs" mb="md" />
            <Stack>
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <Group align="flex-end">
                        <Group>
                            <TextInput {...form.getInputProps("username")} label="Username" placeholder="Username" icon={<IconUser size={18} />} />
                            <Select {...form.getInputProps("status")} label="Status" placeholder="Status" icon={<IconIdBadge size={18} />} data={Object.entries(UserStatusType).map(([label, value]) => ({ label, value }))} clearable />
                        </Group>
                        <Group noWrap spacing="xs" mb={1}>
                            <ActionIcon
                                variant="default"
                                radius="lg"
                                size="lg"
                                onClick={() => {
                                    form.reset();
                                    setFilterValues();
                                }}
                            >
                                <IconRotate size={20} />
                            </ActionIcon>
                            <ActionIcon type="submit" variant="filled" radius="lg" size="lg" color="blue">
                                <IconSearch size={20} />
                            </ActionIcon>
                        </Group>
                    </Group>
                </form>
                <DataGrid
                    api={ApiRoutes.ADMIN}
                    columns={columns}
                    filterValues={filterValues}
                    highlightOnHover
                    paginationProps={{ position: "right" }}
                    rowContextMenuOption={{
                        items: (rowData) => [
                            {
                                label: rowData?.status === UserStatusType.ACTIVE ? "Inactivate" : "Activate",
                                icon: rowData?.status === UserStatusType.ACTIVE ? <IconUserX size={18} color="orange" /> : <IconUserCheck size={18} color="teal" />,
                                onClick: () => updateUserStatus(rowData?.id, rowData?.status === UserStatusType.ACTIVE ? UserStatusType.INACTIVE : UserStatusType.ACTIVE),
                            },
                            {
                                label: "Edit",
                                icon: <IconEdit size={18} />,
                                onClick: () => {
                                    setSelectUser(rowData);
                                    modalHandlers.open();
                                },
                            },
                            // {
                            //     label: "Delete",
                            //     icon: <IconTrashX size={18} color="red" />,
                            //     onClick: () => deleteUser(rowData?.id, rowData?.username),
                            // },
                        ],
                    }}
                    ref={dataGridRef}
                />
            </Stack>
            <CreateUpdateModal
                opened={modalOpened}
                onClose={() => {
                    modalHandlers.close();
                    setSelectUser();
                }}
                user={selectUser}
                createRole={RoleType.ADMIN}
                successCallback={() => dataGridRef.current?.reload()}
            />
        </>
    );
}

Admin.getLayout = function getLayout(page) {
    return <Layout>{page}</Layout>;
};
