import { Avatar, Badge, Button, Divider, Group, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconEdit, IconPlus, IconUserX, IconUserCheck } from "@tabler/icons";
import { createColumnHelper } from "@tanstack/react-table";
import { useMemo, useRef, useState } from "react";
import { DataGrid } from "../../components/elements/DataGrid";
import Layout from "../../components/layout";
import CreateUpdateModal from "../../components/shop/CreateUpdateModal";
import { ApiRoutes, UserStatusType } from "../../constants";
import ApiEngine from "../../utils/ApiEngine";
import { showAlert, numberWithCurrencyFormat, getFormData } from "../../utils/Utils";

function renderStatusBadge(value, badgeProps) {
    let label = "Inactive";
    let color = "orange";
    if (value === 1) {
        label = "Active";
        color = "teal";
    }

    return (
        <Badge color={color} {...badgeProps}>
            {label}
        </Badge>
    );
}

const columnHelper = createColumnHelper();

export default function Shop() {
    const [modalOpened, modalHandlers] = useDisclosure(false);
    const [selectedShop, setSelectedShop] = useState();
    const dataGridRef = useRef();

    const columns = useMemo(
        () => [
            columnHelper.accessor(
                "username",
                {
                    header: "Shop",
                    cell: ({ row, getValue }) => {
                        return <Group>
                            <Avatar src={row.original.profilePicture ?? null} />
                            <Text>{getValue()}</Text>
                        </Group>
                    }
                }
            ),
            columnHelper.accessor("processingFee", {
                header: "Processing Fee",
                cell: ({ row, getValue }) => {
                    return numberWithCurrencyFormat(row.original?.shopProfile?.processingFee) ?? '-';
                },
            }),
            columnHelper.accessor("status", {
                header: "Status",
                cell: ({ getValue }) => {
                    return renderStatusBadge(getValue());
                },
            }),
        ],
        []
    );

    async function updateShopStatus(id, status) {
        try {
            const response = await ApiEngine.put(`${ApiRoutes.SHOP}/${id}`, getFormData({ isActive: status }));

            if (!response.success) {
                throw response.message;
            }

            showAlert("success", response.message);
            dataGridRef.current?.reload();
        } catch (error) {
            showAlert("fail", error);
        }
    }

    return (
        <>
            <Group>
                <Button leftIcon={<IconPlus size={18} />} onClick={modalHandlers.open}>
                    Add
                </Button>
                <Text size="xl">Shop</Text>
            </Group>
            <Divider mt="xs" mb="md" />
            <Stack>
                <DataGrid
                    api={ApiRoutes.SHOP}
                    columns={columns}
                    highlightOnHover
                    paginationProps={{ position: "right" }}
                    rowContextMenuOption={{
                        items: (rowData) => [
                            {
                                label: rowData?.status === UserStatusType.ACTIVE ? "Inactivate" : "Activate",
                                icon: rowData?.status === UserStatusType.ACTIVE ? <IconUserX size={18} color="orange" /> : <IconUserCheck size={18} color="teal" />,
                                onClick: () => updateShopStatus(rowData?.id, rowData?.status === UserStatusType.ACTIVE? false : true),
                            },
                            {
                                label: "Edit",
                                icon: <IconEdit size={18} />,
                                onClick: () => {
                                    setSelectedShop({ ...rowData, password: "", processingFee: rowData?.shopProfile?.processingFee });
                                    modalHandlers.open();
                                },
                            }
                        ],
                    }}
                    ref={dataGridRef}
                />
            </Stack>
            <CreateUpdateModal
                opened={modalOpened}
                onClose={() => {
                    modalHandlers.close();
                    setSelectedShop();
                }}
                user={selectedShop}
                successCallback={() => dataGridRef.current?.reload()}
            />
        </>
    );
}

Shop.getLayout = function getLayout(page) {
    return <Layout>{page}</Layout>;
};
