import { Center, Image, Text } from "@mantine/core";
import { APP_NAME } from "../../constants";

/// <summary>
/// Author: CK
/// </summary>
export default function Brand() {
    return (
        <Center inline>
            {/* <Image width={30} src="/assets/svgs/logo.svg" /> */}
            <Text ml={10} size="md" color="white">
                {APP_NAME}
            </Text>
        </Center>
    );
}
