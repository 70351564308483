import { stringIsNullOrEmpty } from "./Utils";

/// <summary>
/// Author: KurisuCodes
/// </summary>
export const ConfigEnum = {
    TOKEN: "token",
    USER: "userData",
};

var _config = {};
_config[ConfigEnum.TOKEN] = "";
_config[ConfigEnum.USER] = null;

/// <summary>
/// Author: KurisuCodes
/// </summary>
export class Config {
    static _instance = null;

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    static getInstance() {
        if (stringIsNullOrEmpty(Config._instance)) {
            Config._instance = new Config();
            Config._instance.readData();
        }
   
        return Config._instance;
    }

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    getValue(key) {
        return _config[key];
    }

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    getJson(key) {
        return JSON.parse(_config[key]);
    }

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    getCustomJsonObj(configKey, selectedKeys) {
        let configObj = _config[configKey];

        let customObj = {};
        selectedKeys.map((key) => {
            customObj[key] = configObj[key];
        });

        return customObj;
    }

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    setData(key, value) {
        if (_config.hasOwnProperty(key)) {
            _config[key] = value;
            if (typeof window !== undefined) {
                localStorage.setItem(key, JSON.stringify(value));
            }
        }
    }

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    saveData() {
        for (const [key, value] of Object.entries(_config)) {
            if (typeof window !== undefined) {
                localStorage.setItem(key, JSON.stringify(value));
            }
        }
    }

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    readData() {
        for (const [key, value] of Object.entries(_config)) {
            if (typeof window !== undefined) {
                const storageValue = localStorage.getItem(key);

                if (storageValue && !stringIsNullOrEmpty(storageValue)) {
                    try {
                        _config[key] = JSON.parse(storageValue);
                    } catch (e) {
                        _config[key] = storageValue;
                    }
                }
            }
        }
    }
}
