import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import _ROUTES from "./page";
import { _NON_AUTH_ROUTES, _AUTH_ROUTES, Pages } from "./page";
import Layout from "../components/layout";

/// <summary>
/// Author: Saitama
/// </summary>
const Content = () => {
  var _currentLocation = useLocation();
  const _IS_LOGGED_IN = useSelector((state) => state.authState.isLoggedIn);
  var _userData = useSelector((state) => state.authState.userData);

  /// <summary>
  /// Author: Saitama
  /// </summary>
  useEffect(() => {
    if (_currentLocation) {
      window.scrollTo(0, 0);

      var currentRoute = (_IS_LOGGED_IN ? _AUTH_ROUTES.filter(i => i.role == undefined || i.role.includes(_userData.roleId)) : _NON_AUTH_ROUTES).find(
        (route) => _currentLocation.pathname == route.path
      );

      if (currentRoute == null) {
        window.history.pushState({}, document.title, Pages._DEFAULT);
      }
    }
  }, [_currentLocation]);

  return (
    <>
      {
        _IS_LOGGED_IN ?
          <Layout>
            <Routes location={_currentLocation}>
              {
                _AUTH_ROUTES.filter(i => i.role == undefined || i.role.includes(_userData.roleId)).map((route, index) => (
                  <Route key={index} path={route.path} element={route.component} />
                ))
              }
            </Routes>
          </Layout>
          :
          <Routes location={_currentLocation}>
            {
              _NON_AUTH_ROUTES.map((route, index) => (
                <Route key={index} path={route.path} element={route.component} />
              ))
            }
          </Routes>
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state
});

export default connect(mapStateToProps, null)(Content);
