import { useRouter } from "../../hooks/useRouter";
import { Avatar, Box, Center, Grid, Menu, Space, Text, UnstyledButton, useMantineTheme } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import { IconChevronRight, IconEdit, IconLock } from "@tabler/icons";
import { IconLogout } from "@tabler/icons";
import { ApiRoutes, WebRoutes } from "../../constants";
import ApiEngine, { ResponseData } from "../../utils/ApiEngine";
import { Config, ConfigEnum } from "../../utils/Config";
import { showAlert, stringIsNullOrEmpty } from "../../utils/Utils";
import { ChangePasswordModal } from "../admins/ChangePasswordModal";
import CreateUpdateModal from "../admins/CreateUpdateModal";
import { performLogout } from '../../redux/AuthAction';
import { useDispatch, useSelector } from 'react-redux';

/// <summary>
/// Author: CK
/// </summary>
export default function User() {
    const router = useRouter();
    const theme = useMantineTheme();
    const [editUserModalOpened, editUserModalHandlers] = useDisclosure(false);
    const [changePasswordModalOpened, changePasswordModalHandlers] = useDisclosure(false);
    var _dispatch = useDispatch();
    var userData = useSelector((state) => state.authState.userData);

    /// <summary>
    /// Author: CK
    /// </summary>
    const logoutHandler = async () => {
        openConfirmModal({
            title: "Hold on!",
            centered: true,
            children: <Text color="dimmed">Are you sure you would like to logout?</Text>,
            labels: { confirm: "Yes, logout", cancel: "No" },
            onConfirm: async () => {
                await _dispatch(
                    performLogout()
                );

                router.navigate(WebRoutes.LOGIN);
            },
        });
    };

    async function updateLoginUserData() {
        try {
            /** @type {ResponseData} */
            const response = await ApiEngine.get(ApiRoutes.IS_AUTHORISED);

            if (!response.success) {
                Config.getInstance().setData(ConfigEnum.USER, null);
                Config.getInstance().setData(ConfigEnum.TOKEN, null);
                throw response.message;
            }

            let data = response?.data;
            Config.getInstance().setData(ConfigEnum.USER, data);

            // setUserData(data);
        } catch (error) {
            showAlert("fail", error);
        }
    }

    return (
        <>
            <Menu width={200} position="right" shadow="lg" withArrow>
                <Menu.Target>
                    <Box
                        sx={{
                            paddingTop: theme.spacing.sm,
                            marginTop: theme.spacing.xs,
                            borderTop: `1px solid ${theme.colors.gray[6]}`,
                        }}
                    >
                        <UnstyledButton sx={{ width: "100%" }}>
                            <Grid justify="space-between" align="center">
                                <Grid.Col span="auto">
                                    <Center inline>
                                        <Avatar size={30} radius="xl" src={!stringIsNullOrEmpty(userData?.profileImage) ? userData.profileImage : null} />
                                        <Space w="sm" />
                                        <div>
                                            <Text size="sm" color="white" lineClamp={1}>
                                                {userData?.storeName}
                                            </Text>
                                            <Text size={11} color="dimmed">
                                                {userData?.username}
                                            </Text>
                                        </div>
                                    </Center>
                                </Grid.Col>
                                <Grid.Col span={2}>
                                    <IconChevronRight size={20} color="white" />
                                </Grid.Col>
                            </Grid>
                        </UnstyledButton>
                    </Box>
                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Item icon={<IconLock size={18} />} onClick={changePasswordModalHandlers.open}>
                        <Text>Change Password</Text>
                    </Menu.Item>
                    <Menu.Item icon={<IconEdit size={18} />} onClick={editUserModalHandlers.open}>
                        <Text>Edit</Text>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item icon={<IconLogout size={18} />} onClick={logoutHandler}>
                        <Text>Logout</Text>
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
            <CreateUpdateModal opened={editUserModalOpened} onClose={editUserModalHandlers.close} user={userData} successCallback={updateLoginUserData} />
            <ChangePasswordModal opened={changePasswordModalOpened} onClose={changePasswordModalHandlers.close} />
        </>
    );
}
