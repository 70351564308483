import { Box, Menu, MenuItemProps, createStyles, useMantineTheme } from "@mantine/core";
import { useClickOutside, useElementSize, useWindowEvent } from "@mantine/hooks";
import { RowData } from "@tanstack/react-table";
import { ComponentPropsWithoutRef } from "react";

const useStyles = createStyles((theme, { top, left }, getRef) => ({
    container: {
        position: "fixed",
        top,
        left,
    },
}));

/**
 *
 * @param {Object} props
 * @param {function(RowData): (MenuItemProps & {label: string} & ComponentPropsWithoutRef<"button">)[]} props.items
 * @param {RowData} props.rowData
 * @param {number} props.top
 * @param {number} props.left
 * @param {function} props.onClose
 */
export default function RowContextMenu({ items, rowData, top, left, onClose }) {
    useWindowEvent("resize", onClose);
    useWindowEvent("scroll", onClose);
    const theme = useMantineTheme();
    const ref = useClickOutside(onClose);
    const { ref: sizeRef, width, height } = useElementSize();
    const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
    const adjustedTop = top + height + 8 + theme.spacing.xs > windowHeight ? windowHeight - height + 8 - theme.spacing.xs : top;
    const adjustedLeft = left + width + 8 + theme.spacing.xs > windowWidth ? windowWidth - width + 8 - theme.spacing.xs : left;
    const { classes } = useStyles({ top: adjustedTop, left: adjustedLeft });

    return (
        <Box ref={ref} style={{ position: 'absolute', top: adjustedTop, left: adjustedLeft }}>
            <Menu opened={typeof top === "number" && typeof left === "number"} onClose={onClose}>
                <Menu.Dropdown>
                    <div ref={sizeRef}>
                        {items(rowData).map((item, i) => {
                            const { label, ...restItem } = item;
                            return (
                                <Menu.Item key={i} {...restItem}>
                                    {item.label}
                                </Menu.Item>
                            );
                        })}
                    </div>
                </Menu.Dropdown>
            </Menu>
        </Box>
    );
}
