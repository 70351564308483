import { Link } from "react-router-dom";
import { useRouter } from "../../hooks/useRouter";
import { Box, Group, Text, ThemeIcon, UnstyledButton, useMantineTheme } from "@mantine/core";
import { IconUser, IconMessage2, IconFileAnalytics, IconGift, IconLayoutDashboard, IconSettings, IconUsers, IconBuildingStore, IconBuildingWarehouse, IconNumber } from "@tabler/icons";
import { WebRoutes } from "../../constants";
import { RoleType } from "../../constants";
import { useSelector } from 'react-redux';

/// <summary>
/// Author: CK
/// </summary>
const LinkItem = ({ color, icon, label, link, active = false }) => (
    <Link to={link}>
        <UnstyledButton
            component="a"
            sx={(theme) => ({
                display: "block",
                width: "100%",
                padding: theme.spacing.xs,
                borderRadius: theme.radius.sm,
                backgroundColor: active ? theme.fn.darken("#2e3b61", 0.2) : "#2e3b61",

                "&:hover": {
                    backgroundColor: theme.fn.darken("#2e3b61", 0.2),
                },
            })}
        >
            <Group>
                <ThemeIcon color={color} variant="dark">
                    {icon}
                </ThemeIcon>
                <Text size="sm" color="white">
                    {label}
                </Text>
            </Group>
        </UnstyledButton>
    </Link>
);

const data = [
    { icon: <IconLayoutDashboard size={16} />, color: "blue", label: "Dashboard", link: WebRoutes.DASHBOARD, role: RoleType.SHOP },
    { icon: <IconUser size={16} />, color: "indigo", label: "Admins", link: WebRoutes.ADMINS, role: RoleType.SUPER_ADMIN },
    { icon: <IconBuildingStore size={16} />, color: "indigo", label: "Shop", link: WebRoutes.SHOP, role: RoleType.ADMIN },
    { icon: <IconBuildingWarehouse size={16} />, color: "indigo", label: "Branch", link: WebRoutes.BRANCH, role: RoleType.SHOP },
    { icon: <IconUsers size={16} />, color: "indigo", label: "Staffs", link: WebRoutes.STAFFS, role: RoleType.SHOP },
    { icon: <IconNumber size={16} />, color: "indigo", label: "Table", link: WebRoutes.TABLE, role: RoleType.SHOP },
    { icon: <IconGift size={16} />, color: "grape", label: "Gifts", link: WebRoutes.GIFTS, role: RoleType.SHOP },
    { icon: <IconMessage2 size={16} />, color: "grape", label: "Message Template", link: WebRoutes.MESSAGE_TEMPLATE, role: RoleType.SHOP },
    { icon: <IconFileAnalytics size={16} />, color: "pink", label: "Transaction History", link: WebRoutes.REPORT_TRANSACTION_HISTORY, role: RoleType.SHOP },
    { icon: <IconFileAnalytics size={16} />, color: "pink", label: "Settlement", link: WebRoutes.SETTLEMENT, role: RoleType.SHOP },
    { icon: <IconSettings size={16} />, color: "cyan", label: "Payment Gateway", link: WebRoutes.CONFIGURATION_PAYMENT_GATEWAY, role: RoleType.SUPER_ADMIN },
];

/// <summary>
/// Author: CK
/// </summary>
export default function Links() {
    var userData = useSelector((state) => state.authState.userData);
    const router = useRouter();
    const theme = useMantineTheme();
    const links = data.filter(d => d.role >= userData?.roleId).map((link) => <LinkItem {...link} active={router?.pathname === link.link} key={link.label} />);

    return (
        <Box
            sx={{
                paddingTop: theme.spacing.sm,
                marginTop: theme.spacing.xs,
                borderTop: `1px solid ${theme.colors.gray[6]}`,
            }}
        >
            {links}
        </Box>
    );
}
