import { Avatar, Box, Button, FileButton, Grid, Group, Modal, ModalProps, NumberInput, PasswordInput, Select, Stack, TextInput, createStyles } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconArrowUpCircle } from "@tabler/icons";
import { useEffect, useState } from "react";
import { ApiRoutes, PasswordFormType, RoleType, UserType } from "../../constants";
import ApiEngine, { ResponseData } from "../../utils/ApiEngine";
import { getFormData, showAlert, stringIsNullOrEmpty } from "../../utils/Utils";

const useStyles = createStyles((theme, params, getRef) => ({
    avatar: {
        objectFit: "contain",
        backgroundColor: "white",
        border: "2px solid",
        borderColor: theme.colorScheme === "dark" ? "#FFFFFF" : "#000000",
        borderRadius: theme.radius.md,
        width: 100,
        height: 100,
    },
    avatarContainer: {
        position: "relative",
        cursor: "pointer",
        width: "fit-content",
        margin: "auto",

        ".icon.icon-tabler-arrow-up-circle": {
            background: "white",
            position: "absolute",
            bottom: -5,
            left: 0,
            right: -85,
            margin: "auto",
            borderRadius: theme.radius.xl,
            height: 32,
            width: 32,
        },
    },
}));

/**
 * @param {ModalProps & {user?: UserType, successCallback?: function}} props
 */
export default function CreateUpdateModal(props) {
    const { opened, centered = true, createRole, user: userProp, successCallback, ...restProps } = props;
    /** @type {Partial<UserType>|undefined} */
    const userData = {};
    const roleOpts = Object.entries(RoleType)
        .filter(([key, value]) => !(value === RoleType.SUPER_ADMIN || value === userData?.roleId) || value === userProp?.roleId)
        .filter(([key, value]) => createRole == undefined || (createRole == value))
        .map(([key, value]) => ({ label: key, value: `${value}` }));

    const { classes } = useStyles();
    /** @type {[UserType|undefined, Dispatch<SetStateAction<UserType|undefined>>]} */
    const [user, setUser] = useState();

    const form = useForm({
        initialValues: {
            username: "",
            password: "",
            confirmPassword: "",
            roleId: roleOpts[0].value ?? "",
        },
        validate: {
            ...(userProp
                ? {}
                : {
                      password: (value) => (value === "" ? true : undefined),
                      confirmPassword: (value, values) => (value === "" || value !== values.password ? true : undefined),
                  }),
            username: (value) => (value === "" ? true : undefined),
            roleId: (value) => (value === "" ? true : undefined)
        },
    });
    const [file, setFile] = useState();
    const [imageUrl, setImageUrl] = useState();

    /** @param {Partial<UserType> & Partial<PasswordFormType>} values */
    async function onSubmit(values) {
        if (file) {
            values.picture = file;
        }

        let requestFn;
        let url = ApiRoutes.ADMIN;

        if (user) {
            delete values.password;
            delete values.confirmPassword;
            requestFn = ApiEngine.put;
            url += `/${values.id}`;
        } else {
            values.referrerUsername = userData?.username;
            requestFn = ApiEngine.post;
        }

        let body = getFormData(values);

        try {
            /** @type {ResponseData} */
            const response = await requestFn(url, body);

            if (!response.success) {
                throw response.message;
            }
            showAlert("success", response.message);
            typeof successCallback === "function" && successCallback(values);
            props.onClose();
        } catch (error) {
            showAlert("fail", error);
        }
    }

    useEffect(() => {
        file ? setImageUrl(URL.createObjectURL(file)) : setImageUrl();
    }, [file]);

    useEffect(() => {
        return () => imageUrl && URL.revokeObjectURL(imageUrl);
    }, [imageUrl]);

    useEffect(() => {
        if (opened) {
            setFile();
            form.reset();
            if (userProp) {
                setUser({ ...userProp, roleId: `${userProp.roleId}` });
                form.setValues({ ...userProp, roleId: `${userProp.roleId}` });
            } else {
                setUser();
            }
        }
    }, [opened]);

    return (
        <Modal opened={opened} size={user ? "md" : "lg"} centered={centered} title={"Form"} {...restProps}>
            <form onSubmit={form.onSubmit(onSubmit)}>
                <Stack>
                    <FileButton onChange={setFile} accept="image/png,image/jpeg">
                        {(fileInputProps) => (
                            <Box {...fileInputProps} className={classes.avatarContainer}>
                                <Avatar className={classes.avatar} src={imageUrl ? imageUrl : !stringIsNullOrEmpty(user?.profilePicture) ? user.profilePicture : null} />
                                <IconArrowUpCircle />
                            </Box>
                        )}
                    </FileButton>
                    <Grid>
                        {!user && (
                            <Grid.Col xs={6}>
                                <Stack>
                                    <TextInput {...form.getInputProps("username")} label={"Username"} placeholder={"Username"} withAsterisk />
                                    <PasswordInput {...form.getInputProps("password")} label={"Password"} placeholder={"Password"} withAsterisk />
                                </Stack>
                            </Grid.Col>
                        )}
                        <Grid.Col xs={user ? 12 : 6}>
                            <Stack>
                                <Select {...form.getInputProps("roleId")} label={"Role"} placeholder={"Role"} data={roleOpts} disabled={userData?.roleId >= user?.roleId} withAsterisk />
                                {
                                    user && <PasswordInput {...form.getInputProps("password")} label={"Password"} placeholder={"Password"} withAsterisk />
                                }
                                <PasswordInput {...form.getInputProps("confirmPassword")} label={"Confirm Password"} placeholder={"Confirm Password"} withAsterisk />
                            </Stack>
                        </Grid.Col>
                    </Grid>
                    <Group position="right">
                        <Button variant="default" onClick={props.onClose}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="filled">
                            Save
                        </Button>
                    </Group>
                </Stack>
            </form>
        </Modal>
    );
}
