import { ActionIcon, Avatar, Badge, BadgeProps, Button, Divider, Group, Select, Stack, Text, TextInput, createStyles } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import { IconEdit, IconPlus, IconUserX, IconUserCheck, IconPassword } from "@tabler/icons";
import { ColumnHelper, createColumnHelper } from "@tanstack/react-table";
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { DataGrid } from "../../components/elements/DataGrid";
import Layout from "../../components/layout";
import CreateUpdateModal from "../../components/branch/CreateUpdateModal";
import { ApiRoutes, UserStatusType } from "../../constants";
import ApiEngine from "../../utils/ApiEngine";
import { showAlert, stringIsNullOrEmpty, numberWithCurrencyFormat, getFormData } from "../../utils/Utils";

function renderStatusBadge(value, badgeProps) {
    let label = "Inactive";
    let color = "orange";
    if (value === true) {
        label = "Active";
        color = "teal";
    }

    return (
        <Badge color={color} {...badgeProps}>
            {label}
        </Badge>
    );
}

const columnHelper = createColumnHelper();

export default function Branch() {
    const [modalOpened, modalHandlers] = useDisclosure(false);
    const [selectedBranch, setSelectedBranch] = useState();
    const dataGridRef = useRef();

    const columns = useMemo(
        () => [
            columnHelper.accessor(
                "name",
                {
                    header: "Branch",
                    cell: ({ row, getValue }) => {
                        return <Group>
                            <Avatar src={row.original.logo ?? null} />
                            <Text>{getValue()}</Text>
                        </Group>
                    }
                }
            ),
            columnHelper.accessor("businessNature", {
                header: "Business Nature",
                cell: ({ getValue }) => {
                    return getValue();
                },
            }),
            columnHelper.accessor("isActive", {
                header: "Status",
                cell: ({ getValue }) => {
                    return renderStatusBadge(getValue());
                },
            }),
        ],
        []
    );

    async function updateBranchStatus(id, status) {
        try {
            const response = await ApiEngine.put(`${ApiRoutes.BRANCH}/${id}`, getFormData({ isActive: status }));

            if (!response.success) {
                throw response.message;
            }

            showAlert("success", response.message);
            dataGridRef.current?.reload();
        } catch (error) {
            showAlert("fail", error);
        }
    }

    return (
        <>
            <Group>
                <Button leftIcon={<IconPlus size={18} />} onClick={modalHandlers.open}>
                    Add
                </Button>
                <Text size="xl">Branch</Text>
            </Group>
            <Divider mt="xs" mb="md" />
            <Stack>
                <DataGrid
                    api={ApiRoutes.BRANCH}
                    columns={columns}
                    highlightOnHover
                    paginationProps={{ position: "right" }}
                    rowContextMenuOption={{
                        items: (rowData) => [
                            {
                                label: rowData?.isActive === true ? "Inactivate" : "Activate",
                                icon: rowData?.isActive === true ? <IconUserX size={18} color="orange" /> : <IconUserCheck size={18} color="teal" />,
                                onClick: () => updateBranchStatus(rowData?.id, rowData?.isActive === true ? false : true),
                            },
                            {
                                label: "Edit",
                                icon: <IconEdit size={18} />,
                                onClick: () => {
                                    setSelectedBranch({ ...rowData });
                                    modalHandlers.open();
                                },
                            }
                        ],
                    }}
                    ref={dataGridRef}
                />
            </Stack>
            <CreateUpdateModal
                opened={modalOpened}
                onClose={() => {
                    modalHandlers.close();
                    setSelectedBranch();
                }}
                user={selectedBranch}
                successCallback={() => dataGridRef.current?.reload()}
            />
        </>
    );
}

Branch.getLayout = function getLayout(page) {
    return <Layout>{page}</Layout>;
};
