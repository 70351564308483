import { _LOGIN_SUCCESS, _LOGIN_FAILED, _LOGOUT, _ACTION_FORBIDDEN } from './AuthAction';
import { clearPermissionData } from "../utils/PermissionChecker"
import { SessionKey } from "../constants";
import { Config, ConfigEnum } from "../utils/Config";

const _INITIAL_STATE = {
    userData: {},
    isLoggedIn: false,
    loginMessage: "",
    loginStateInitialized: false,
    isForbidden: false
};

/// <summary>
/// Author: -
/// </summary>
export default (state = _INITIAL_STATE, action) => {
    switch (action.type) {
        case _LOGIN_SUCCESS:
            Config.getInstance().setData(ConfigEnum.USER, action.userData);
            Config.getInstance().setData(ConfigEnum.TOKEN, action?.token);

            return {
                ...state,
                userData: action.userData,
                isLoggedIn: true,
                loginMessage: "",
                loginStateInitialized: true
            }
            break;
        case _LOGIN_FAILED:
            return {
                loginMessage: action.message,
                isLoggedIn: false,
                userData: {},
                loginStateInitialized: true
            }
            break;
        case _LOGOUT:
            Config.getInstance().setData(ConfigEnum.USER, null);
            Config.getInstance().setData(ConfigEnum.TOKEN, null);
            clearPermissionData();
            
            return {
                userData: {},
                isLoggedIn: false,
                loginMessage: "",
                loginStateInitialized: true,
                creditLimit: 0
            };
            break;
        case _ACTION_FORBIDDEN:
            return {
                ...state,
                isForbidden: action.value
            };
            break;
        default:
            return state;
            break;
    }
};
