import { Avatar, Box, Button, FileButton, Grid, Group, Modal, NumberInput, Select, Stack, TextInput, createStyles } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconArrowUpCircle } from "@tabler/icons";
import { useEffect, useState } from "react";
import { ApiRoutes, RoleType } from "../../constants";
import ApiEngine from "../../utils/ApiEngine";
import { getFormData, showAlert, stringIsNullOrEmpty, isObjectEmpty } from "../../utils/Utils";
import { useSelector } from "react-redux";

const useStyles = createStyles((theme, params, getRef) => ({
    avatar: {
        objectFit: "contain",
        backgroundColor: "white",
        border: "2px solid",
        borderColor: theme.colorScheme === "dark" ? "#FFFFFF" : "#000000",
        borderRadius: theme.radius.md,
        width: 100,
        height: 100,
    },
    avatarContainer: {
        position: "relative",
        cursor: "pointer",
        width: "fit-content",
        margin: "auto",

        ".icon.icon-tabler-arrow-up-circle": {
            background: "white",
            position: "absolute",
            bottom: -5,
            left: 0,
            right: -85,
            margin: "auto",
            borderRadius: theme.radius.xl,
            height: 32,
            width: 32,
        },
    },
}));

export default function CreateUpdateModal(props) {
    const { opened, centered = true, user: userProp, successCallback, ...restProps } = props;
    const { classes } = useStyles();
    const [user, setUser] = useState({});
    const [shopOption, setShopOption] = useState([]);
    const userData = useSelector((state) => state.authState.userData);
    const form = useForm({
        initialValues: {
            name: "",
            businessNature: "",
            phoneNumber: "",
            description: "",
            processingFee: 0,
            address1: "",
            address2: "",
            postalCode: "",
            city: "",
            state: "",
            shopId: "",
        },
        validate: {
            shopId: (value) => (value === "" ? true : undefined),
            name: (value) => (value === "" ? true : undefined),
            processingFee: (value) => (typeof value === "undefined" ? true : undefined),
        },
    });
    const [file, setFile] = useState();
    const [imageUrl, setImageUrl] = useState();

    async function onSubmit(values) {
        if (file) {
            values.logo = file;
        }

        let url = ApiRoutes.BRANCH;
        let body = getFormData(values);
        let response;

        try {
            if (isObjectEmpty(user)) {
                response = await ApiEngine.post(url, body);
            }
            else {
                response = await ApiEngine.put(`${url}/${user.id}`, body);
            }

            if (!response.success) {
                throw response.message;
            }

            showAlert("success", response.message);
            typeof successCallback === "function" && successCallback(values);
            props.onClose();
        } catch (error) {
            showAlert("fail", error);
        }
    }

    useEffect(() => {
        file ? setImageUrl(URL.createObjectURL(file)) : setImageUrl();
    }, [file]);

    useEffect(() => {
        return () => imageUrl && URL.revokeObjectURL(imageUrl);
    }, [imageUrl]);

    useEffect(() => {
        if (opened) {
            setFile();
            form.reset();
            getShopOption();

            if (userProp) {
                setUser({ ...userProp });
                form.setValues({ ...userProp });
            } else {
                setUser({});
            }
        }
    }, [opened]);

    async function getShopOption() {
        try {
            if (userData?.roleId > RoleType.ADMIN) {
                setShopOption([{ label: userData.username.toUpperCase(), value: userData.id }]);
                form.setFieldValue('shopId', userData.id);
            }
            else {
                const response = await ApiEngine.get(`${ApiRoutes.SHOP}?isActive=true`);

                if (!response.success) {
                    throw response.message;
                }

                setShopOption(response.data.map(shop => { return { label: shop.username.toUpperCase(), value: shop.id }}));
            }
        } catch (error) {
            showAlert("fail", error);
        }
    }

    return (
        <Modal opened={opened} size={user ? "md" : "lg"} centered={centered} title={"Form"} {...restProps}>
            <form onSubmit={form.onSubmit(onSubmit)}>
                <Stack>
                    <FileButton onChange={setFile} accept="image/png,image/jpeg">
                        {(fileInputProps) => (
                            <Box {...fileInputProps} className={classes.avatarContainer}>
                                <Avatar className={classes.avatar} src={imageUrl ? imageUrl : !stringIsNullOrEmpty(user?.logo) ? user.logo : null} />
                                <IconArrowUpCircle />
                            </Box>
                        )}
                    </FileButton>
                    <Grid>
                        <Grid.Col xs={12}>
                            <Select {...form.getInputProps("shopId")} label={"Shop"} placeholder={"Shop"} data={shopOption} disabled={userData?.roleId > RoleType.ADMIN} withAsterisk />
                        </Grid.Col>
                        <Grid.Col xs={6}>
                            <TextInput {...form.getInputProps("name")} label={"Name"} placeholder={"Name"} withAsterisk />
                        </Grid.Col>
                        <Grid.Col xs={6}>
                            <TextInput {...form.getInputProps("phoneNumber")} label={"Phone Number"} placeholder={"Phone Number"} />
                        </Grid.Col>
                        <Grid.Col xs={6}>
                            <TextInput {...form.getInputProps("businessNature")} label={"Business Nature"} placeholder={"Business Nature"} />
                        </Grid.Col>
                        <Grid.Col xs={12}>
                            <TextInput {...form.getInputProps("description")} label={"Description"} placeholder={"Description"} />
                        </Grid.Col>
                        <Grid.Col xs={6}>
                            <TextInput {...form.getInputProps("address1")} label={"Address 1"} placeholder={"Address 1"} />
                        </Grid.Col>
                        <Grid.Col xs={6}>
                            <TextInput {...form.getInputProps("address2")} label={"Address 2"} placeholder={"Address 2"} />
                        </Grid.Col>
                        <Grid.Col xs={6}>
                            <NumberInput {...form.getInputProps("postalCode")} label={"Postal Code"} placeholder={"Postal Code"} />
                        </Grid.Col>
                        <Grid.Col xs={6}>
                            <TextInput {...form.getInputProps("city")} label={"City"} placeholder={"City"} />
                        </Grid.Col>
                        <Grid.Col xs={6}>
                            <TextInput {...form.getInputProps("state")} label={"State"} placeholder={"State"} />
                        </Grid.Col>
                    </Grid>
                    <Group position="right">
                        <Button variant="default" onClick={props.onClose}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="filled">
                            Save
                        </Button>
                    </Group>
                </Stack>
            </form>
        </Modal>
    );
}
