import { useRouter } from "../../hooks/useRouter";
import { Anchor, Button, Card, Center, FocusTrap, Image, PasswordInput, Stack, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useToggle } from "@mantine/hooks";
import { APP_NAME, ApiRoutes, WebRoutes } from "../../constants";
import { showAlert } from "../../utils/Utils";
import { performLogin } from '../../redux/AuthAction';
import { useDispatch, useSelector } from 'react-redux';

/// <summary>
/// Author: CK
/// </summary>
export default function Login() {
    const router = useRouter();
    const [isLoading, toggleLoading] = useToggle();
    var _dispatch = useDispatch();

    const form = useForm({
        initialValues: {
            username: "",
            password: "",
        },
        validate: {
            username: (value) => (value === "" ? "Username is required." : null),
            password: (value) => (value === "" ? "Password is required." : null),
        },
    });

    /// <summary>
    /// Author: CK
    /// </summary>
    const loginHandler = async (values) => {
        toggleLoading();

        try {

            let loginResponse = await _dispatch(
                performLogin(values.username, values.password)
            );

            if (loginResponse) {
                router.navigate(WebRoutes.DASHBOARD);
            }
        } catch (error) {
            showAlert("fail", error);
        } finally {
            toggleLoading();
        }
    };

    return (
        <Center style={{ width: "100vw", height: "100vh" }}>
            <Stack>
                <Center>
                    {/* <Image width={40} src="/assets/svgs/logo.svg" alt="" /> */}
                    <Text size="xl" color="white">
                        {APP_NAME}
                    </Text>
                </Center>

                <FocusTrap>
                    <Card style={{ width: "30vw", maxWidth: "360px" }} p="lg" radius="md">
                        <form onSubmit={form.onSubmit(loginHandler)}>
                            <TextInput withAsterisk label="Username" {...form.getInputProps("username")} />
                            <PasswordInput withAsterisk mt={10} label="Password" {...form.getInputProps("password")} />

                            <Button type="submit" mt={25} fullWidth loading={isLoading}>
                                Login
                            </Button>
                        </form>
                        <Center mt={15}>
                            <Anchor component="button" type="button">
                                <Text size="sm">Forgot your password?</Text>
                            </Anchor>
                        </Center>
                    </Card>
                </FocusTrap>
            </Stack>
        </Center>
    );
}
